//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify'
import store from '../store/index'
import MemberPasswordDialog from '@/components/MemberPasswordDialog.vue'
import ProductRateDialog from '@/components/ProductRateDialog.vue'
import MemberRateDetailDialog from '@/components/MemberRateDetailDialog.vue'
import MemberRateTeamDialog from '@/components/MemberRateTeamDialog.vue'
//import EpointPasswordDialog from '@/components/EpointPasswordDialog.vue'
//import ExtendBillDialog from '@/components/ExtendBillDialog.vue'

export default {
  components: {
    MemberPasswordDialog,
    ProductRateDialog,
    MemberRateDetailDialog,
    MemberRateTeamDialog
    //EpointPasswordDialog,
    //ExtendBillDialog
  },
  data () {
    return {
      ui_size: "",
      current_session_mcode: "",
      mtype: "",
      bill_dialog_show: false,
      navigationActive: false,
      roundList: [],
      roundOptions:this.roundList,
      round_selected: {label: "", value: "" },
      datalist: [],
      selected: [],
      columns: [
        //{ name: 'mcode', align: 'center', label: 'รหัสสมาชิก', field: 'mcode'},
        { name: 'prodcode', align: 'center', label: 'รหัสสินค้า', field: 'prodcode'},
        { name: 'prodname',  align: 'left', label: 'สินค้า', field: 'prodname' },
        { name: 'total_qty',  align: 'right', label: 'จำนวนรวม (แพ็ค)', field: row => row.total_qty, format: val => `${this.formatnumber(val,0)}` },
        { name: 'total_qty_team', align: 'center', label: '' },
        { name: 'total_qty_detail', align: 'center', label: '' },
        { name: 'rate_price',  align: 'right', label: 'เรทราคา', field: row => row.rate_price, format: val => `${this.formatnumber(val,2)}`},
        { name: 'rate_price_detail', align: 'center', label: '' }
        /*
        { name: 'from_qty',  align: 'center', label: 'ช่วงจำนวนเริ่มเรท', field: 'from_qty' },
        { name: 'to_qty',  align: 'center', label: 'ช่วงจำนวนสิ้นสุด', field: 'to_qty' }
        */
      ],
      product : {
        prodcode: "",
        prodname: ""
      },
      product_rate_dialog_show: false,
      member_rate_detail_dialog_show: false,
      member_rate_team_dialog_show: false,
      member_password_dialog_show: false,
      /*
      epoint_password_dialog_show: false,
      mta_expire: false,
      mta_expire_txt: "",
      mta_expire_dd: "",
      mta_expire_mon: "",
      mta_expire_yyyy: "",

      topup_expire: false,
      topup_expire_txt: "",
      topup_expire_dd: "",
      topup_expire_mon: "",
      topup_expire_yyyy: "",
      */
      username: "",
      name_t: "",
      rank: "",
      mdate: "",
      sp_code: "",
      sp_name: "",
      /*
      expiredate: "",
      s_rank: "",
      up_code: "",
      up_name: "",
      side: "",
      sposition: "",
      pv_l: "",
      pv_r: "",
      code_l: "",
      code_r: "",
      */
      position: "",
      ppvtotal: "",
      epoint: "",
      pagination: {
        rowsPerPage: 0
      },
    }
  },
  methods: {
      setUISize() {
            //if (this.$q.screen.width > 1023) { 
            if (this.$q.screen.width > 800) {
            //console.log('screen large');
            this.ui_size = "D";
            }
            else {
            //console.log('screen small');
            this.ui_size = "M";
            }
      },
      activateNavigation () {
        this.navigationActive = true
      },
      deactivateNavigation () {
        this.navigationActive = false
      },
      onKey (evt) {
        if (
          this.navigationActive !== true ||
          [ 33, 34, 35, 36, 38, 40 ].indexOf(evt.keyCode) === -1 ||
          this.$refs.myTable === void 0
        ) {
          return
        }

        evt.preventDefault()

        const { computedRowsNumber, computedRows } = this.$refs.myTable

        if (computedRows.length === 0) {
          return
        }

        const currentIndex = this.selected.length > 0 ? computedRows.indexOf(this.selected[0]) : -1
        const currentPage = this.pagination.page
        const rowsPerPage = this.pagination.rowsPerPage === 0 ? computedRowsNumber : this.pagination.rowsPerPage
        const lastIndex = computedRows.length - 1
        const lastPage = Math.ceil(computedRowsNumber / rowsPerPage)

        let index = currentIndex
        let page = currentPage

        switch (evt.keyCode) {
          case 36: // Home
            page = 1
            index = 0
            break
          case 35: // End
            page = lastPage
            index = rowsPerPage - 1
            break
          case 33: // PageUp
            page = currentPage <= 1 ? lastPage : currentPage - 1
            if (index < 0) {
              index = 0
            }
            break
          case 34: // PageDown
            page = currentPage >= lastPage ? 1 : currentPage + 1
            if (index < 0) {
              index = rowsPerPage - 1
            }
            break
          case 38: // ArrowUp
            if (currentIndex <= 0) {
              page = currentPage <= 1 ? lastPage : currentPage - 1
              index = rowsPerPage - 1
            }
            else {
              index = currentIndex - 1
            }
            break
          case 40: // ArrowDown
            if (currentIndex >= lastIndex) {
              page = currentPage >= lastPage ? 1 : currentPage + 1
              index = 0
            }
            else {
              index = currentIndex + 1
            }
            break
        }

        if (page !== this.pagination.page) {
          this.pagination = {
            ...this.pagination,
            page
          }

          this.$nextTick(() => {
            const { computedRows } = this.$refs.myTable
            this.selected = [ computedRows[Math.min(index, computedRows.length - 1)] ]
          })
        }
        else {
          this.selected = [ computedRows[index] ]
        }
        if (this.selected[0].mcode != this.session_mcode) {
          this.changeMcode(this.selected[0].mcode);
        }
        //this.$emit("onSeleted", this.selected[0].mcode);
      },
      requestRowClick: function(selected_mcode, selected_row) {
          if (this.selected[0].mcode !== selected_mcode) {
            //this.selected.splice(0,1);
            this.selected = [];
            this.selected.push(selected_row);
            this.changeMcode(selected_row.mcode);
            //this.$emit("onSeleted", selected_row);
          }
      },
      refresh (done) {
        setTimeout(() => {
          this.fetchData();
          done();
        }, 1000)
      },
      member_password_dialog_hide () {
        this.member_password_dialog_show = false;
      },
      product_rate_dialog_hide () {
        this.product_rate_dialog_show = false;
      },
      member_rate_detail_dialog_hide() {
        this.member_rate_detail_dialog_show = false;
      },
      member_rate_team_dialog_hide() {
        this.member_rate_team_dialog_show = false;
      },
      /*
      epoint_password_dialog_hide () {
        this.epoint_password_dialog_show = false;
      },
      */
     /*
      fetchMcodeList: function () {
        let self = this;
        self.$q.loading.show();
        self.axios({
          method: "post",
          headers: {
              'Authorization': self.jwt_token
          },
          url: self.url_api + "MemberClient/Member.Mcode.List.JSON.aspx"
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          self.datalist = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
          self.selected.push(self.datalist.find(datalist => datalist.mcode === self.session_mcode));
          //console.log(responseDoc);
          self.$q.loading.hide();
        })
        .catch(function (error) {
          console.log("Error :" + error);
          self.$q.loading.hide();
        });
      },
      */
     open_rate_team_dialog: function(selected_product) {
        // this.product is Obj var 
        this.product = selected_product;
        this.member_rate_team_dialog_show = true;
     },
     open_rate_detail_dialog: function(selected_product) {
        // this.product is Obj var 
        this.product = selected_product;
        this.member_rate_detail_dialog_show = true;
     },
     open_product_rate_dialog: function(selected_product) {
       // this.product is Obj var 
        this.product = selected_product;
        this.product_rate_dialog_show = true;
     },
     fetchMcodeRateList: function () {
        let self = this;
        self.$q.loading.show();
        const params = new URLSearchParams();
        params.append('round', self.round_selected.value);
        self.axios({
          method: "post",
          headers: {
              'Authorization': self.jwt_token
          },
          url: self.url_api + "MemberClient/Member.Rate.List.JSON.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          let record_count = parseInt(responseDoc.getElementsByTagName('recordcount')[0].textContent);
          if (record_count > 0) {
            self.datalist = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
          } else {
            self.datalist = [];
          }
          self.$q.loading.hide();
        })
        .catch(function (error) {
          console.log("Error :" + error);
          self.$q.loading.hide();
        });
      },
      fetchData: function () {
        let self = this;
        self.$q.loading.show();
        const params = new URLSearchParams();
        params.append('mcode', self.current_session_mcode);
        
        self.axios({
          method: "post",
          headers: {
            'Authorization': self.jwt_token
          },
          url: self.url_api + "MemberClient/main_page.JSON.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          /*
          self.mta_expire_dd = responseDoc.getElementsByTagName('mta_expire_dd')[0].textContent;
          self.mta_expire_mon = responseDoc.getElementsByTagName('mta_expire_mon')[0].textContent;
          self.mta_expire_yyyy = responseDoc.getElementsByTagName('mta_expire_yyyy')[0].textContent;
          self.mta_expire_txt = responseDoc.getElementsByTagName('mta_expire_txt')[0].textContent;
          if (self.mta_expire_txt.length > 0) { self.mta_expire = true } else { self.mta_expire = false }
          self.topup_expire_dd = responseDoc.getElementsByTagName('topup_expire_dd')[0].textContent;
          self.topup_expire_mon = responseDoc.getElementsByTagName('topup_expire_mon')[0].textContent;
          self.topup_expire_yyyy = responseDoc.getElementsByTagName('topup_expire_yyyy')[0].textContent;
          self.topup_expire_txt = responseDoc.getElementsByTagName('topup_expire_txt')[0].textContent;
          if (self.topup_expire_txt.length > 0) { self.topup_expire = true } else { self.topup_expire = false }
          self.expiredate = responseDoc.getElementsByTagName('expiredate')[0].textContent;
          self.pv_l = responseDoc.getElementsByTagName('pv_l')[0].textContent;
          self.pv_r = responseDoc.getElementsByTagName('pv_r')[0].textContent;
          self.code_l = responseDoc.getElementsByTagName('code_l')[0].textContent;
          self.code_r = responseDoc.getElementsByTagName('code_r')[0].textContent;
          self.position = responseDoc.getElementsByTagName('position')[0].textContent;
          self.sponsor = responseDoc.getElementsByTagName('sponsor')[0].textContent;
          self.s_rank = responseDoc.getElementsByTagName('s_rank')[0].textContent;
          */
          self.rank = responseDoc.getElementsByTagName('rank')[0].textContent;
          self.name_t = responseDoc.getElementsByTagName('name_t')[0].textContent;
          self.mdate = responseDoc.getElementsByTagName('mdate')[0].textContent;
          self.sp_code = responseDoc.getElementsByTagName('sp_code')[0].textContent;
          self.sp_name = responseDoc.getElementsByTagName('sp_name')[0].textContent;
          self.epoint = responseDoc.getElementsByTagName('epoint')[0].textContent;
          self.ppvtotal = responseDoc.getElementsByTagName('ppvtotal')[0].textContent;
          
          self.$q.loading.hide();
          self.fetchMcodeRateList();
        })
        .catch(function (error) {
          console.log("Error :" + error);
          self.$q.loading.hide();
        });
      },
      /*
      changeMcode : function(mcode) {
          store.set("SESSION_MCODE_LOGIN", mcode);
          this.current_session_mcode = mcode;
      },
      */
      fetchRoundmo: function () {
        let self = this;
        self.axios({
          method: "post",
          headers: {
            'Authorization': self.jwt_token
          },
          url: self.url_api + "MemberClient/Roundmo.ListAll.JSON.aspx"
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          let round = responseDoc.getElementsByTagName('current_round')[0].textContent;
          self.roundList = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
          /*
          console.log("result round selected -> :" + round);
          console.log("json roundList:" + responseDoc.getElementsByTagName('json')[0].textContent);
          */
          self.setRoundmoSelected(round);
        })
        .catch(function (error) {
          console.log("Roundmo List Error :" + error);
        });
      },
      setRoundmoSelected(selected_value) {
        var found = this.roundList.find(function(element) { 
          return element.label == selected_value; 
        });
        this.round_selected = found;
      },
      filterFnAutoselect (val, update) {
        // call abort() at any time if you can't retrieve data somehow
        setTimeout(() => {
          update(
            () => {
             if (val === '') {
                this.roundOptions = this.roundList
              }
              else {
                const needle = val.toLowerCase()
                this.roundOptions = this.roundList.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
                //console.log("filter result:" + this.roundOptions[0].label);
                /*
                const result = members.filter((jsonOptions) => {
                  return jsonOptions.label > 25
                })
                this.options = result*/
              }
            },
            // next function is available in Quasar v1.7.4+;
            // "ref" is the Vue reference to the QSelect
            ref => {
              if (val !== '' && ref.options.length > 0 && ref.optionIndex === -1) {
                ref.moveOptionSelection(1, true) // focus the first selectable option and do not update the input-value
                ref.toggleOption(ref.options[ref.optionIndex], true) // toggle the focused option
              }
            }
          )
        }, 300)
      },
      abortFilterFn () {
        // console.log('delayed filter aborted')
      },
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN'),
      session_id_no: get('SESSION_ID_NO'),
      session_mtype: get('SESSION_MTYPE'),
      session_mdate: get('SESSION_MDATE'),
      session_mname: get('SESSION_MCODE_NAME'),
      sponsor: function() {
        return this.sp_code + " - " + this.sp_name;
      },
  },
  watch: {
    /*
    "selected" : function () {
    },
    */
    "$q.screen.width"() {
      this.setUISize()
    }, 
    current_session_mcode : function() {
      this.fetchData();
    },
    round_selected : function() {
      this.fetchMcodeRateList()
    }
  },
  mounted: function () {
    //this.fetchMcodeList();
    //alert("main mount");
    this.current_session_mcode = this.session_mcode;
    if (this.session_mtype == "M") {
      this.fetchRoundmo();
      this.fetchData();
    }
  },
  created () {
      //console.log('Component created.');
      this.setUISize();
      store.set("APP_TOOLBAR_TITLE","หน้าหลัก");
      this.$q.loading.show();
  }    
}
