//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify';
import DistrictDialog from '@/components/DistrictDialog.vue'

export default {
  name: 'DealerAddressDialog',
  props: ["show_status", "bill_no"],
  components: {
    DistrictDialog
  },
  data () {
    return {
      loading: false,
      fixed: false,
      mcode: "",
      receiver_name: "",
      address1: "",
      address2: "",
      province_selected: "", 
      zipcode: "",
      phone_no: "",
      mobile_no: "",
      provinceList: null,
      provinceOptions: this.provinceList,
      district_dialog_show: false,
    }
  },
  methods: {
      popup_district_dialog() {
        //alert("popup_district_dialog");
        this.district_dialog_show = true;
      },
      district_dialog_hide() {
          this.district_dialog_show = false;
        },
      setContactAddress(province, district, subdistrict, zipcode) {
          if (province.length > 0 && district.length > 0 && subdistrict.length > 0) {
            let district_abbr = "อ.";
            let subdistrict_abbr = "ต.";
            if (province == "กรุงเทพฯ" || province == "กรุงเทพมหานคร") {
              district_abbr = "เขต";
              subdistrict_abbr = "แขวง";
            }
            this.address2 =  subdistrict_abbr + subdistrict + " " + district_abbr + district;
            this.setProvinceSelected(province);
            this.zipcode = zipcode
          }  
        },
      abortFilterFn () {
        // console.log('delayed filter aborted')
        },
      filterFnAutoselect (val, update) {
          // call abort() at any time if you can't retrieve data somehow
          setTimeout(() => {
            update(
              () => {
                if (val === '') {
                  this.provinceOptions = this.provinceList
                }
                else {
                  const needle = val.toLowerCase()
                  this.provinceOptions = this.provinceList.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
                  //console.log("filter result:" + this.provinceOptions[0].label);
                  /*
                  const result = members.filter((jsonOptions) => {
                    return jsonOptions.label > 25
                  })
                  this.options = result*/

                }
              },

              // next function is available in Quasar v1.7.4+;
              // "ref" is the Vue reference to the QSelect
              
              ref => {
                if (val !== '' && ref.options.length > 0 && ref.optionIndex === -1) {
                  ref.moveOptionSelection(1, true) // focus the first selectable option and do not update the input-value
                  ref.toggleOption(ref.options[ref.optionIndex], true) // toggle the focused option
                }
              }
            )
          }, 300)
        },
      setProvinceSelected(selected_value) {
          var found = this.provinceList.find(function(element) { 
            return element.label == selected_value; 
          });
          this.province_selected = found;
        },    
      fetchProvince: function () {
          let self = this;
          self.axios({
          headers: {
            'Authorization': self.jwt_token
          },
          method: "post",
            url: self.url_api + "MemberClient/Province.ListAll.JSON.aspx"
          })
          .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            self.provinceList = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
            if (self.province_selected == "") {
              self.province_selected = self.provinceList[0];
            } 
          })
          .catch(function (error) {
            console.log("Error :" + error);
          });
        },
      fetchData: function () {
          let self = this;
          //self.$q.loading.show();
          const params = new URLSearchParams();
          params.append('bill_no', self.bill_no);
          self.axios({
            method: "post",
            headers: {
              'Authorization': self.jwt_token
            },
            url: self.url_api + "MemberClient/eStore.Delivery.FOrders.Info.Address.JSON.aspx",
            data: params
          })
          .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            //console.log("Start Map Data");
            self.mcode = responseDoc.getElementsByTagName('mcode')[0].textContent;
            self.receiver_name = responseDoc.getElementsByTagName('receiver')[0].textContent;
            self.address1 = responseDoc.getElementsByTagName('address1')[0].textContent;
            self.address2 = responseDoc.getElementsByTagName('address2')[0].textContent;
            self.zipcode = responseDoc.getElementsByTagName('zipcode')[0].textContent;
            self.mobile_no = responseDoc.getElementsByTagName('mobile')[0].textContent;
            let province = responseDoc.getElementsByTagName('province')[0].textContent;
            self.setProvinceSelected(province);
            self.$q.loading.hide();
          })
          .catch(function (error) {
            console.log("Error :" + error);
            self.$q.loading.hide();
          });
        },

      confirm_update() {
              this.$q.dialog({
                title: "ยืนยันคำสั่ง",
                message: "บันทึกข้อมูลการจัดส่ง" ,
                persistent: true,
                html: true,
                class: "app-font",
                style: "background-color:#f0c05a; color:#ffffff;",
                ok: {
                  label: "ตกลง",
                  color: "white",
                  outline: true,
                  icon: "check_circle",
                },
                cancel: {
                  label: "ยกเลิก",
                  color: "white",
                  flat: true,
                  icon: "cancel"
                },
              }).onOk(() => { 
                this.$q.loading.show();
                let self = this;
                setTimeout( function() { 
                  self.post_update();
                }, 1000);
                // console.log('>>>> OK')
              }).onOk(() => {
                // console.log('>>>> second OK catcher')
              }).onCancel(() => {
                // console.log('>>>> Cancel')
              }).onDismiss(() => {
                // console.log('I am triggered on both OK and Cancel')
              })
      },

      post_update: function () {
        let self = this;
        self.$q.loading.show();
        self.loading = true;
        const params = new URLSearchParams();
        params.append('bill_no', self.bill_no);
        params.append('address1', self.address1);
        params.append('address2', self.address2);
        params.append('province', self.province_selected.label);
        params.append('zip', self.zipcode);
        params.append('mobile', self.mobile_no);
        self.axios({
          method: 'post',
          headers: {
              'Authorization': self.jwt_token
          },
          timeout: 600,
          url: self.url_api + 'Sale/eStore.Delivery.Info.Address.Update.JSON.aspx',
          data: params
        })
        .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let update_status = responseDoc.getElementsByTagName('status')[0].textContent;
            //let message = responseDoc.getElementsByTagName('message')[0].textContent;
            if (update_status == "OK") {
                  self.$q.loading.hide();
                  self.triggerPositive();
            } else {
                  self.triggerNegative();
                  self.$q.loading.hide();
            }
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.loading = false;
            self.triggerNegative();
            self.$q.loading.hide();
        });
      }, 
      triggerCustomRegisteredType2 () {
        // this one overrides some of the original
        // options of the "my-notif" registered type
        this.$q.notify({
          type: 'my-notif',
          icon: 'contactless',
          message: `This notification is using a custom type.`,
          caption: `It overrides the type's default icon and color.`,
          color: 'primary',
          progress: true,
          textColor: 'white'
        })
      },
      triggerPositive () {
        this.$q.notify({
          type: 'positive',
          progress: true,
          message: `บันทึกข้อมูลเรียบร้อย`
        });
        this.fixed = false;
        this.$emit("updated", true);
      },
      triggerNegative () {
        this.$q.notify({
          type: 'negative',
          message: `ไม่สามารถบันทึกข้อมูลได้ `
        })
      },

      triggerWarning (msg_text) {
        this.$q.notify({
          type: 'warning',
          message: msg_text
        })
      },
      restrictInput(event) {
        let allowedKeys = [8, 33, 64, 35, 36, 37, 42]
        let key = event.keyCode;
        if ((key >= 48 && key <= 57) || 
            (key >= 65 && key <= 90) || 
            (key >= 97 && key <= 122) || allowedKeys.includes(key)) {
          //console.log("Restrict Pass key:" + key + " value : " + String.fromCharCode(key));
        } else {
          event.preventDefault();
          //console.log("Restrict Not Pass key:" + key + " value : " + String.fromCharCode(key));
        }
      },
      onHide() {
        //console.log("onHide");
        this.$emit("onHide", "");
        //this.$parent.scode_dialog_show = false;
      }
    /*
    ,
    // when props.ok() gets called
    onOk (data) { },

    // when props.cancel() gets called
    onCancel () { },

    // when we show it to the user
    onShow () { },

    // when it gets hidden
    onHide () { },

    // custom handler
    async choose (okFn, hero) {
      if (this.name.length === 0) {
        this.$q.dialog({
          title: 'Please specify your name!',
          message: `Can't buy tickets without knowing your name.`
        })
      }
      else {
        await okFn()
        this.$q.notify(`Ok ${this.name}, going with ${hero}`)
      }
    }
    */
  },
  mounted () {
      //console.log('mounted.');
      //this.delivery_code = this.track_no;
  },
  watch: {
    show_status: function () {
      this.fixed = this.show_status;
      if (this.show_status) {
        this.fetchData();
      }
    }
  },
  computed: {
      jwt_token: get('JWT_TOKEN'),
      url_api: get('URL_API_PATH'),
      session_username: get('SESSION_USERNAME'),
      editableColor: get("EDITABLE_COLOR"), 
      receiver_name_isValid() {
        return this.receiver_name.length >= 5;
      },
      address1_isValid () {
        return this.address1.length >= 5;
      },
      zipcode_isValid () {
        return this.zipcode.length == 5;
      },
      mobile_no_isValid () {
        return this.mobile_no.length == 10;
      }
  },
  created () {
    this.fetchProvince();
    /**
     * The reason we have this here
     * is that the type needs to be
     * registered before using it.
     *
     * The best place would be a boot file instead
     * of a .vue file, otherwise it'll keep on
     * registering it every time your component
     * gets to be used :)
     */

    this.$q.notify.registerType('my-notif', {
      icon: 'announcement',
      progress: true,
      color: 'brown',
      textColor: 'white',
      classes: 'glossy'
    })
  }
 
}
