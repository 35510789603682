//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify';
import { date } from "quasar";
import store from '../store/index';
import MbscDatePicker from '@/components/MbscDatePicker.vue'
import DistrictDialog from '@/components/DistrictDialog.vue';

export default {
  name: 'signin',
  components: {
    MbscDatePicker,
    DistrictDialog
  },
  data () {
    return {
      ui_size: "",
      valid_id_card_img: false,
      id_card_uploader_readonly: true,
      address_district_dialog_show: false,
      address_dialog_focus: "",
      new_mcode: "",
      finishDialog: false,
      confirm: false,
      acceptCondition: false,
      input_color: "white",
      mcode: "",
      name_t: "",
      sp_code: "A000001",
      sp_name: "",
      username: "",
      password: "",
      isPwd: true,
      status_text: "",
      status_color: "light-green-7",
      date_text: "",
      login_btn_disable: false,
      readonly: false,
      login_panel_class: "hidden",
      genderList: [
            {label: "นาย", value: "1" },
            {label: "น.ส.", value: "2" },
            {label: "นาง", value: "3" },
            {label: "อื่นๆ", value: "4" }
        ],
      gender_selected: {label:"นาย", value:"1" },
      valid_id_card: false,
      valid_name_t: false,
      valid_phone_no: false,
      email_valid: false,
      bankList: null,
      bankOptions:this.bankList,
      bank_selected: {label: "", value: "" },
      new_member: { gender: "1", name_t: "", 
                      id_card: "", birthdate: "01-01-2520", 
                      idaddress: "", idprovince_id: "", idprovince_name: "",
                      iddistrict_id: "", iddistrict_name: "",
                      idsubdistrict_id: "", idsubdistrict_name: "", idzipcode: "",
                      address: "", province_id:"", province_name:"", 
                      district_id:"", district_name:"", 
                      subdistrict_id:"", subdistrict_name:"", zipcode:"", 
                      phone_no: "", email: "", 
                      bankcode: "", branch:"", acc_no: "", acc_name: "",
                      heir: "" },

      member: { gender: "1", name_t: "", 
                      id_card: "", birthdate: "01-01-2520", 
                      idaddress: "", idprovince_id: "", idprovince_name: "",
                      iddistrict_id: "", iddistrict_name: "",
                      idsubdistrict_id: "", idsubdistrict_name: "", idzipcode: "",
                      address: "", province_id: "", province_name: "", 
                      district_id: "", district_name: "", 
                      subdistrict_id: "", subdistrict_name: "", zipcode: "", 
                      phone_no: "", email: "", 
                      bankcode: "", branch:"", acc_no: "", acc_name: "",
                      heir: "" },
    }
  },      
  methods: {
    gotoSignin()  {
      this.finishDialog = false;
      this.$router.push({ name:"Signin"});
    },
    openFinishDialog() {
        this.finishDialog=true;
    },
    resetIDCardUploader() {
        this.valid_id_card_img = false;
        this.id_card_uploader_readonly = false;
        this.$refs.id_card_uploader.reset();
    },
    registerProcess() {
      //this.confirm = true;
      if (this.compute_valid_all) {
          //this.submit_btn_disable = true;
          this.$q.dialog({
            title: "ยืนยันการบันทึกข้อมูล",
            message:" เลขบัตรประชาชน : <b>" + this.member.id_card + "</b><br>" +
                    " ชื่อ-นามสกุล : <b>" + this.member.name_t + "</b></br>" +
                    " ที่อยู่ในบัตร : <b>" + this.member.address + "</b><br>" +
                    " ดำบล / อำเภอ / จังหวัด : <b>" + this.member.idsubdistrict_name + " / " + this.member.iddistrict_name + " / " + this.member.idprovince_name + "</b> <br>" +
                    " ที่อยู่ติดต่อ : <b>" + this.member.address + "</b><br>" +
                    " ดำบล / อำเภอ / จังหวัด : <b>" + this.member.subdistrict_name + " / " + this.member.district_name + " / " + this.member.province_name + "</b> <br>" +
                    " เบอร์มือถือ : <b>" + this.member.phone_no + "</b></br>",
            persistent: true,
            html: true,
            class: "app-font",
            style: "background-color:#FFFFFF; color:#000000;",
            ok: {
              label: "ตกลง",
              color: 'positive'
            },
            cancel: {
              label: "ยกเลิก"
            },
          }).onOk(() => {
              this.post_data();
            // console.log('>>>> OK')
          }).onOk(() => {
            // console.log('>>>> second OK catcher')
          }).onCancel(() => {
            this.submit_btn_disable = false;
            // console.log('>>>> Cancel')
          }).onDismiss(() => {
            // console.log('I am triggered on both OK and Cancel')
          });
      } else {
          let error_msg = "";
          let error_count = 0;
          /*
          if (this.member.password.length == 0) {
            error_msg = error_msg + " รหัสผ่าน";
            error_count = error_count + 1;
          }
          if (this.member.epoint_password.length == 0) {
            error_msg = error_msg + " รหัสผ่าน E-Bonus";
            error_count = error_count + 1;
          }
          */
          if (!this.compute_valid_name_t) {
            error_msg = error_msg + " ชื่อ-นามสกุล";
            error_count = error_count + 1;
          }
          if (!this.compute_valid_id_card) {
            error_msg = error_msg + " เลขที่บัตรประชาชน";
            error_count = error_count + 1;
          }
          if (!this.compute_valid_email && this.member.email.length > 0) {
            error_msg = error_msg + " อีเมล";
            error_count = error_count + 1;
          }
          let self = this;
          let err_txt;
          if (error_count > 1) {
            err_txt = self.replaceAll(error_msg.trim()," ",", ");
          } else {
            err_txt = error_msg;
          }
          self.triggerNegative("ตรวจสอบข้อมูล " + err_txt);
          //this.triggerNegative(error_msg);
      }
     
    },
    post_data() {
        let self = this;
        self.$q.loading.show();
        const params = new URLSearchParams();
        params.append('idcard', self.member.id_card);
        params.append('gender', self.gender_selected.value);
        params.append('name_t', self.member.name_t.trim());
        params.append('birthdate', self.member.birthdate);
        params.append('idaddress1', self.member.idaddress.trim());
        params.append('idaddress2', self.idaddress_detail);
        params.append('idprovince', self.member.idprovince_name);
        params.append('idprovince_id', self.member.idprovince_id);
        params.append('iddistrict_id', self.member.iddistrict_id);
        params.append('idsubdistrict_id', self.member.idsubdistrict_id);
        params.append('idzipcode', self.member.idzipcode);
        params.append('address1', self.member.address.trim());
        params.append('address2', self.address_detail);
        params.append('province', self.member.province_name);
        params.append('province_id', self.member.province_id);
        params.append('district_id', self.member.district_id);
        params.append('subdistrict_id', self.member.subdistrict_id);
        params.append('zipcode', self.member.zipcode);
        params.append('phone_no', self.member.phone_no.trim());
        params.append('email', self.member.email.trim());
        params.append('bankcode', self.bank_selected.value);
        params.append('branch', self.member.branch.trim());
        params.append('acc_no', self.member.acc_no.trim());
        params.append('acc_name', self.member.acc_name.trim());
        params.append('heir',self.member.heir);
        self.axios({
          method: "post",
          url: self.url_api + "MemberClient/Agent.Profile.Insert.JSON.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          let result = responseDoc.getElementsByTagName('insert_status')[0].textContent;
          let commit = responseDoc.getElementsByTagName('commit')[0].textContent;
          let message = responseDoc.getElementsByTagName('message')[0].textContent;
          //let result_password = responseDoc.getElementsByTagName('password')[0].textContent;
          if (result == "OK" && commit == "1") {
              self.new_mcode = responseDoc.getElementsByTagName('mcode')[0].textContent;
              self.triggerPositive(message);
              //self.view_form();
              //self.view_function();
              //self.$emit("inserted",self.member.mcode);
              self.$q.loading.hide();
              self.finishDialog = true;
          } else {
              self.triggerNegative(message);
              self.$q.loading.hide();
          }
          self.submit_btn_disable = false;
        })
        .catch(function (error) {
            self.triggerNegative("ไม่สามารถบันทึกข้อมูลได้");
            console.log("Error :" + error);
            self.$q.loading.hide();
            self.submit_btn_disable = false;
        });
    },
    setContactAddress(area) {
      if (area.province_name.length > 0 && area.district_name.length > 0 && area.subdistrict_name.length > 0) {
        //alert("address_dialog_focus : " + this.address_dialog_focus);
        if (this.address_dialog_focus == "id") {
            /*
            alert(" area.district_id : " + area.district_id);
            alert(" area.district_name : " + area.district_name); 
            alert(" area.subdistrict_id : " + area.subdistrict_id); 
            alert(" area.subdistrict_name : " + area.subdistrict_name); 
            alert(" area.zipcode : " + area.zipcode); 
            */
            this.member.idprovince_id = area.province_id;
            this.member.idprovince_name = area.province_name;
            this.member.iddistrict_id = area.district_id;
            this.member.iddistrict_name = area.district_name;
            this.member.idsubdistrict_id = area.subdistrict_id;
            this.member.idsubdistrict_name = area.subdistrict_name;
            this.member.idzipcode = area.zipcode;
        } else {
            this.member.province_id = area.province_id;
            this.member.province_name = area.province_name;
            this.member.district_id = area.district_id;
            this.member.district_name = area.district_name;
            this.member.subdistrict_id = area.subdistrict_id;
            this.member.subdistrict_name = area.subdistrict_name;
            this.member.zipcode = area.zipcode;
        }
        
      }  
    },
    /*
    setContactAddress(province, province_name, district, district_name, subdistrict, subdistrict_name, zipcode) {
      if (province_name.length > 0 && district_name.length > 0 && subdistrict_name.length > 0) {
        this.member.province_id = province;
        this.member.province_name = province_name;
        this.member.district_id = district;
        this.member.district_name = district_name;
        this.member.subdistrict_id = subdistrict;
        this.member.subdistrict_name = subdistrict_name;
        this.member.zipcode = zipcode;
      }  
    },
    */
    gotoMain()  {
      this.finishDialog = false;
      this.$router.push({ name:"Main"});
    },
    validate_email() {
      this.member.email = this.member.email.trim();
      let email = this.member.email;
      let regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      if (regexEmail.test(email)) {
        this.email_valid = true;
      } else {
        this.email_valid = false;
        this.member.email = "";
      }
    },
    updateDate(e, title) {
      //console.log("update mdate to :" + e);
      switch (title) {
        case "วันที่สมัคร":
          this.member.mdate = e;
          break;
        case "วันเกิด":
          this.member.birthdate = e;
          break;  
      }
    },
    district_dialog_hide() {
      if (this.address_district_dialog_show) { this.address_district_dialog_show = false; }
    },
    
    triggerWarning (msg_text) {
      this.$q.notify({
        type: 'warning',
        message: msg_text,
        classes:'app-font'
      })
    },
    triggerPositive (msg_text) {
      this.$q.notify({
        type: 'positive',
        progress: true,
        message: msg_text,
        classes:'app-font'
      });
    },
    triggerNegative (msg_text) {
      this.$q.notify({
        type: 'negative',
        message: msg_text,
        classes:'app-font'
      })
    },
    fetchMobileNo: function () {
        let self = this;
        if (self.compute_valid_phone_no) {
            self.$q.loading.show();
            const params = new URLSearchParams();
            params.append("phone_no", self.member.phone_no);
            self.axios({
              method: "post",
              headers: {
                'Authorization': self.jwt_token
              },
              timeout: 0, // Let's say you want to wait at least unlimit mins
              url: self.url_api + "MemberClient/Member.MobileNo.Check.JSON.aspx",
              data: params
            })
            .then(function (response) {
                //console.log("response data");
                let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
                let check_mobile_no = parseInt(responseDoc.getElementsByTagName('check_mobile_no')[0].textContent);
                if (check_mobile_no == 0) {
                  self.valid_phone_no = true;
                } else {
                  self.valid_phone_no = false;
                  self.triggerWarning("เบอร์มือถือนี้ได้ลงทะเบียนไปแล้ว");
                }
                self.$q.loading.hide();
            })
            .catch(function (error) {
                console.log("Error :" + error);
                self.valid_phone_no = false;
                self.member.phone_no = "";
                self.triggerNegative("ไม่สามารถทำการตรวจสอบข้อมูล");
                self.$q.loading.hide();
            });
            self.$q.loading.hide();
        } else {
          if (this.member.phone_no.length > 0 && this.member.phone_no.length < 10) {
              self.triggerNegative("กรุณาตรวจสอบเบอร์มือถือไม่ครบ 10 หลัก");
              this.valid_phone_no = false;
          }
        }
    },
    fetchIDCard: function () {
        let self = this;
        if (self.compute_valid_id_card) {
            self.$q.loading.show();
            const params = new URLSearchParams();
            params.append("id_card_no", self.member.id_card);
            self.axios({
              method: "post",   
              headers: {
                'Authorization': self.jwt_token
              },           
              timeout: 0, // Let's say you want to wait at least unlimit mins
              url: self.url_api + "MemberClient/Member.IDCard.Check.JSON.aspx",
              data: params
            })
            .then(function (response) {
                //console.log("response data");
                let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
                let check_idcard = parseInt(responseDoc.getElementsByTagName('check_idcard')[0].textContent);
                if (check_idcard == 0) {
                  self.valid_id_card = true;
                  self.id_card_uploader_readonly = false;
                } else {
                  self.valid_id_card = false;
                  self.triggerWarning("เลขที่บัตรประชาชนนี้ได้ลงทะเบียนไปแล้ว");
                }
                self.$q.loading.hide();
            })
            .catch(function (error) {
                console.log("Error :" + error);
                self.valid_id_card = false;
                self.member.id_card = "";
                self.triggerNegative("ไม่สามารถทำการตรวจสอบข้อมูล");
                self.$q.loading.hide();
            });
            self.$q.loading.hide();
        } else {
          this.valid_id_card = false;
          if (this.member.id_card.length == 13) {
              self.triggerNegative("เลขที่บัตรประชาชนไม่ถูกต้อง กรุณาตรวจสอบ");
          }
        }
    },
    validateIDCardImage: function () {
        let self = this;
        self.$q.loading.show();
        

        if (self.compute_valid_id_card) {
            let birth_year_BD = parseInt(self.member.birthdate.substr(6,4));
            let birth_year_AD = birth_year_BD - 543;
            const params = new URLSearchParams();
            params.append("id_no", self.member.id_card);
            params.append("birth_year_BD", birth_year_BD);
            params.append("birth_year_AD", birth_year_AD);
            self.axios({
              method: "post",   
              headers: {
                'Authorization': self.jwt_token
              },           
              timeout: 0, // Let's say you want to wait at least unlimit mins
              url: self.url_api + "MemberClient/Uploader.IDCard.Validate.JSON.aspx",
              data: params
            })
            .then(function (response) {
                //console.log("response data");
                let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
                let check_idcard = parseInt(responseDoc.getElementsByTagName('image_valid')[0].textContent);
                if (check_idcard == 1) {
                  self.valid_id_card_img = true;
                  self.id_card_uploader_readonly=true;
                } else {
                  self.valid_id_card_img = false;
                  self.id_card_uploader_readonly=false;
                  self.resetIDCardUploader();
                  self.triggerWarning("รูปบัตรประชาชนไม่ถูกต้อง กรุณาถ่ายรูปบัตรแล้วอัปโหลดใหม่");
                }
                self.$q.loading.hide();
            })
            .catch(function (error) {
                console.log("Error :" + error);
                self.valid_id_card_img = false;
                self.resetIDCardUploader();
                self.triggerNegative("ไม่สามารถทำการตรวจสอบข้อมูล");
                self.$q.loading.hide();
            });
            self.$q.loading.hide();
        } else {
          this.valid_id_card = false;
          if (this.member.id_card.length == 13) {
              self.triggerNegative("เลขที่บัตรประชาชนไม่ถูกต้อง กรุณาตรวจสอบ");
          }
        }
    },
    fetchName: function () {
        let self = this;
        self.member.name_t = self.member.name_t.trim();
        if (self.member.name_t.length > 0) {
            self.$q.loading.show();
            const params = new URLSearchParams();
            params.append("name_t", self.member.name_t);
            self.axios({
              method: "post", 
              headers: {
                'Authorization': self.jwt_token
              },             
              timeout: 0, // Let's say you want to wait at least unlimit mins
              url: self.url_api + "MemberClient/Member.Name_t.Check.JSON.aspx",
              data: params
            })
            .then(function (response) {
                //console.log("response data");
                let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
                let check_name_t = parseInt(responseDoc.getElementsByTagName('check_name_t')[0].textContent);
                if (check_name_t == 0) {
                  self.valid_name_t = true;
                } else {
                  self.valid_name_t = false;
                  self.triggerWarning("ชื่อ-นามสกุล นี้ได้ลงทะเบียนไปแล้ว");
                }
                self.$q.loading.hide();
            })
            .catch(function (error) {
                console.log("Error :" + error);
                self.valid_name_t = false;
                self.member.name_t = "";
                self.triggerNegative("ไม่สามารถทำการตรวจสอบข้อมูล");
                self.$q.loading.hide();
            });
            self.$q.loading.hide();
        } else {
          this.valid_name_t = false;
        }
    },
    fetchBank: function () {
      let self = this;
      self.axios({
        method: "post",
        headers: {
          'Authorization': self.jwt_token
        },
        url: self.url_api + "MemberClient/Bank.ListAll.JSON.aspx"
      })
      .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          self.bankList = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
      })
      .catch(function (error) {
        console.log("Error :" + error);
      });
    },
    filterFnAutoselectBank (val, update) {
        // call abort() at any time if you can't retrieve data somehow
        setTimeout(() => {
          update(
            () => {
              if (val === '') {
                this.bankOptions = this.bankList
              }
              else {
                const needle = val.toLowerCase()
                this.bankOptions = this.bankList.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
                /*
                const result = members.filter((jsonOptions) => {
                  return jsonOptions.label > 25
                })
                this.options = result*/
              }
            },

            // next function is available in Quasar v1.7.4+;
            // "ref" is the Vue reference to the QSelect
            ref => {
              if (val !== '' && ref.options.length > 0 && ref.optionIndex === -1) {
                ref.moveOptionSelection(1, true) // focus the first selectable option and do not update the input-value
                ref.toggleOption(ref.options[ref.optionIndex], true) // toggle the focused option
              }
            }
          )
        }, 300)
    },
    abortFilterFn () {
        // console.log('delayed filter aborted')
    },
    acc_name_clone_name_t () {
      this.member.acc_name = this.member.name_t;
    },
    clone_idaddress () {
      this.member.address = this.member.idaddress;
      this.member.district_id = this.member.iddistrict_id;
      this.member.district_name = this.member.iddistrict_name;
      this.member.subdistrict_id = this.member.idsubdistrict_id;
      this.member.subdistrict_name = this.member.idsubdistrict_name;
      this.member.province_id = this.member.idprovince_id;
      this.member.province_name = this.member.idprovince_name;
      this.member.zipcode = this.member.idzipcode;
    },
    emailRestrictChar(e) {
        let restrictionType = /[0-9 A-Z a-z @ . _]/g;
        var regex = new RegExp(restrictionType);
        if (!regex.test(e.key)) {
              e.preventDefault();
        }
    },

    setUISize() {
        //if (this.$q.screen.width > 1023) { 
        if (this.$q.screen.width > 800) {
          //console.log('screen large');
          this.ui_size = "D";
        }
        else {
          //console.log('screen small');
          this.ui_size = "M";
        }
    },
  },  
  mounted: function () {
      this.fetchBank();
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      appLangUI: get('APP_LANG_UI'),
      appShutdownValue: get('APP_SHUTDOWN_VALUE'),
      session_date: get('SESSION_DATE'),
      session_mcode: get('SESSION_MCODE_LOGIN'),
      session_mname: get('SESSION_MCODE_NAME'),
      getHeader() {
        let headerArr = [
          { name: 'Authorization', value: this.jwt_token },
          { name: 'X-Custom-Data', value: this.member.id_card }
        ];
        return headerArr
      },
      getUrl (files) {
        return this.url_api + "MemberClient/UploaderIDCard.aspx?id_no=" + this.member.id_card + "&count=" + files.length;
      },
      member_age: function() {
        try {
          if (this.member.birthdate.length == 10) {
            let ad_year = parseInt(this.member.birthdate.substring(6,10)) - 543;
            let date1 = Date.now();
            let date2 = new Date(ad_year, this.member.birthdate.substring(3,5), this.member.birthdate.substring(0,2));
            let unit = "years"
            let member_age_value = date.getDateDiff(date1, date2, unit)
            return member_age_value;
          } else {
            return 0;
          }
        }
        catch(err) {
          return 0;
        }
      },
      bank_acc_name_isValid() {
          let acc_name = this.member.acc_name.trim();
          if (acc_name.length > 2) {
            return true;
          } else {
            return false;
          }
      },
      bank_acc_no_isValid() {
        let acc_no = this.replaceAll(this.replaceAll(this.member.acc_no,"#",""),"-","");
        if (acc_no.length == 10) {
            return true;
        } else {
            return false;
        }
      },
      bank_isValid() {
          if (this.bank_selected.value != "") {
            return true;
          } else {
            return false;
          }
      },
      compute_valid_all() {
        if (this.valid_name_t && this.valid_id_card && this.valid_phone_no 
            && this.compute_valid_address && this.compute_valid_district
            /*&& this.valid_id_card_img*/ && this.member_age >= 18) {
            return true;
        } else {
            if (this.member_age < 18) {
              this.triggerNegative("อายุต่ำกว่า 18 ไม่สามารถทำการสมัครได้");
            }
            return false;
        }
      },  
      compute_valid_address() {
        if (this.member.address.trim().length >= 1) {
            return true;
        } else {
            return false;
        }
      },
      compute_valid_phone_no() {
        if (this.member.phone_no.length == 10) {
            return true;
        } else {
            return false;
        }
      },
      compute_valid_email() {
        if (this.member.email != undefined && this.member.email.length > 0 && !this.readonly) {
          
          if (this.email_valid) {
             return true;
          } else {
             return false;
          }
          
          //return true;
        } else {
          return false;
        }
      },
      email_isValid() {
        if (this.email_valid && !this.readonly) {
          //alert("email valid");
          return true;
        } else {
           if (this.member.email == "" || this.readonly ) {
            return true;
          } else {
            return false;
          }
          /*
          if (this.member.email.length == 0 || this.readonly ) {
            return true;
          } else {
            return false;
          }
          */
        }
      },
      compute_valid_district() {
        if (this.member.province_id != "" && this.member.district_id != "" && this.subdistrict_id != "" && this.zipcode != "" && this.zipcode != "00000") {
          return true;
        } else {
          return false;
        }
      },
      idaddress_detail() {
        let subdistrict_label = "ต.";
        let district_label = "อ.";
        if (this.member.idprovince_name == "กรุงเทพมหานคร") {
          subdistrict_label = "แขวง";
          district_label = "เขต";
        }
        if (this.member.idsubdistrict_name.length > 0) {
          subdistrict_label = subdistrict_label + this.member.idsubdistrict_name;
        } else {
          subdistrict_label = "";
        }
        if (this.member.iddistrict_name.length > 0) {
          district_label = district_label + this.member.iddistrict_name;
        } else {
          district_label = "";
        }
        return (subdistrict_label + ' ' + district_label).trim(); 
      },
      address_detail() {
        let subdistrict_label = "ต.";
        let district_label = "อ.";
        if (this.member.province_name == "กรุงเทพมหานคร") {
          subdistrict_label = "แขวง";
          district_label = "เขต";
        }
        if (this.member.subdistrict_name.length > 0) {
          subdistrict_label = subdistrict_label + this.member.subdistrict_name;
        } else {
          subdistrict_label = "";
        }
        if (this.member.district_name.length > 0) {
          district_label = district_label + this.member.district_name;
        } else {
          district_label = "";
        }
        return (subdistrict_label + ' ' + district_label).trim(); 
      },
      compute_valid_name_t() {
        if (this.member.name_t.trim().length > 0 && this.valid_name_t && !this.readonly) {
          return true;
        } else {
          return false;
        }
      },
      compute_valid_id_card() {
        /*
        if (this.member.id_card.length == 13 && !this.readonly) {
          return true;
        } else {
          return false;
        }
        */
        if (this.member.id_card.length == 13) {
            let digit;
            let i, checksum, checkdigit;
            digit = this.member.id_card.split("");
            checksum = 0;
            for (i = 0; i < (digit.length-1); i++) { 
              checksum = checksum + (digit[i] * (13-i));
            }
            checkdigit = 11 - (checksum % 11);
            //alert("checksum : " + checksum + " checkdigit : " + checkdigit  + " last digit : " + digit[12]);
            let checkdigit_str = checkdigit.toString();
            var d;
            if (checkdigit_str.length > 1) {
              let p;
              p = checkdigit_str.length - 1;
              d = checkdigit_str.substr(p,1);
            } else {
              d = checkdigit_str;
            }
            if (d == digit[12]) {
              return true;
            } else {
              return false;
            }
        } else {
          return false;
        }
      },
      compute_valid_bank() {
        if (this.bank_isValid && !this.readonly && this.bank_selected.value.length > 0) {
          return true;
        } else {
          return false;
        }
      },
      compute_valid_acc_no() {
        let acc_no = this.replaceAll(this.replaceAll(this.member.acc_no,"#",""),"-","");
        if (acc_no.length == 10) {
          return true;
        } else {
          return false;
        }
      },
      compute_valid_acc_name() {
        if (this.member.acc_name.trim().length > 1 && !this.readonly) {
          return true;
        } else {
          return false;
        }
      },
      compute_valid_heir() {
        if (this.member.heir.trim().length > 1 && !this.readonly) {
          return true;
        } else {
          return false;
        }
      },
      password_isValid() {
        if (this.member.password.length > 0) {
          return true;
        } else {
          return false;
        }
      },
  },
  created () {
    //this.$q.loading.show();
    //this.init();
    this.setUISize();
    store.set("APP_TOOLBAR_TITLE","ลงทะเบียนตัวแทนใหม่");
  },
  watch: {
    "$q.screen.width"() {
        this.setUISize();
    },
    "member.name_t": function() {
      if (this.member.name_t.indexOf("  ") > 0) {
        this.member.name_t = this.replaceAll(this.member.name_t,"  "," ");
      }
      this.member.name_t = this.trim(this.member.name_t);
    },
    "member.email"() {
        let invalidChars = /[^0-9 ^a-z ^A-Z -.@]/gi
        if (invalidChars.test(this.member.email)) {
            this.member.email = this.member.email.value.replace(invalidChars, "");
        }
    },
    "member.id_card"() {
        //this.triggerPositive(this.member.id_card.length);
        if (this.member.id_card.length == 13) {
            this.fetchIDCard();
        } else {
            this.valid_id_card = false;
            this.valid_id_card_img = false;
            this.id_card_uploader_readonly = false;
            setTimeout(() => {
                this.$refs.id_card_uploader.reset();
            }, 1);
            setTimeout(() => {
                this.id_card_uploader_readonly = true;
            }, 1);
            
        }
    },
    appShutdownValue: function () {
        if (this.appShutdownValue == "0") {
            this.status_text = "Online";
            this.status_color = "light-green-7";
            this.readonly = false;
            this.login_panel_class = "";
        } else {
            if (this.appShutdownValue == "1") {
              this.status_text = "Offline";
              this.status_color = "red-7";
              this.readonly = true;
              //alert("hidden");
              this.login_panel_class = "hidden";
            }  
        }
    }
  }
}
