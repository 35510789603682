//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify';

export default {
  name: 'MemberRateDetailtDialog',
  props: ["show_status","round","descript"],
  data () {
    return {
      ui_size: "",
      loading: false,
      total_record: 0,
      total_page: 1,
      total_page_suffix: '0',
      rowsPerFetch: 2000,
      pagination: {
        rowsPerPage: 0
      },
      pageID: 1,
      findWord: '',
      current_row: 1,
      min_row: 0,
      max_row: 0,
      datalist: [],
      selected: [],
      columns: [
        { name: 'rownum', align: 'center', label: 'ลำดับ', field: 'rownum' },
        { name: 'prodcode', align: 'center', label: 'รหัสสินค้า', field: 'prodcode'},
        //{ name: 'prodname', align: 'left', label: 'สินค้า', field: 'prodname'},
        { name: 'number',  align: 'center', label: 'เลขที่บิล', field: 'number' },
        { name: 'previous_rate',  align: 'right', label: 'เรทบิล', field: 'previous_rate' },
        { name: 'rate_price',  align: 'right', label: 'เรทประจำตัว', field: 'rate_price' },
        { name: 'rate_diff',  align: 'right', label: 'เรทส่วนต่าง', field: 'rate_diff' },
        { name: 'qty',  align: 'center', label: 'จำนวน', field: 'qty' },
        { name: 'rate_bonus',  align: 'right', label: 'เรทโบนัส', field: 'rate_bonus' },
      ],
      /* 
      customDialogModel: false,
      name: ''
      */
      fixed: false
    }
  },
  methods: {
    resetSearch() {
        this.findWord = "";
        this.fetchDataList();
    },
    setUISize() {
        //if (this.$q.screen.width > 1023) { 
        if (this.$q.screen.width > 800) {
          //console.log('screen large');
          this.ui_size = "D";
        }
        else {
          //console.log('screen small');
          this.ui_size = "M";
        }
    },    
    previous_page: function() {
      if (parseInt(this.pageID) > 1) {
        this.pageID = parseInt(this.pageID) - 1;
      }
    },
    next_page: function() {
      if (parseInt(this.pageID) < parseInt(this.total_page)) {
        this.pageID = parseInt(this.pageID) + 1;
      }
    },
    requestRowClick: function(selected_mcode, selected_row) {
        if (this.selected.mcode !== selected_mcode) {
          this.selected.splice(0,1);
          this.selected.push(selected_row);
          this.$emit("onSeleted", selected_row);
        }
    },
    fetchDataList: function () {
      let self = this;
      self.loading = true;
      const params = new URLSearchParams();
      params.append('pageSize', this.rowsPerFetch);
      params.append('pageID', this.pageID);
      params.append('findWord', this.findWord);
      params.append('round', this.round);
      params.append('mcode', this.session_mcode);
      self.axios({
        method: 'post',
        headers: {
          'Authorization': self.jwt_token
        },        
        timeout: 0,
        url: self.url_api + 'MemberClient/Bonusmo.Rate.Detail.JSON.aspx',
        data: params
      })
      .then(function (response) {
        //console.log("response data");
        //console.log("result :" + response.data);
        let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
       
        self.datalist = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
        self.total_record = responseDoc.getElementsByTagName('recordcount')[0].textContent;
        self.total_page = parseInt(responseDoc.getElementsByTagName('pagecount')[0].textContent);
        //self.total_page_suffix = "/" + self.total_page;
        self.loading = false;
       
      })
      .catch(function (error) {
          console.log("Error :" + error);
          self.loading = false;
      });
    }, 
    onHide() {
      //console.log("onHide");
      this.$emit("onHide", "");
      //this.$parent.scode_dialog_show = false;
    }
    /*
    ,
    // when props.ok() gets called
    onOk (data) { },

    // when props.cancel() gets called
    onCancel () { },

    // when we show it to the user
    onShow () { },

    // when it gets hidden
    onHide () { },

    // custom handler
    async choose (okFn, hero) {
      if (this.name.length === 0) {
        this.$q.dialog({
          title: 'Please specify your name!',
          message: `Can't buy tickets without knowing your name.`
        })
      }
      else {
        await okFn()
        this.$q.notify(`Ok ${this.name}, going with ${hero}`)
      }
    }
    */
  },
  mounted () {
      //this.fetchDataList();
  },
  watch: {
    show_status: function () {
      //console.log("fixed :" + this.fixed);
      this.fixed = this.show_status;
       if (this.show_status) {
        this.selected.splice(0,1);
      }
      if (this.fixed) {
        this.fetchDataList();
      }
    },
    pageID: function () {
      this.fetchDataList();
    },
    "$q.screen.width"() {
      this.setUISize();
    },
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      session_mcode: get('SESSION_MCODE_LOGIN'),
      full_width_vw : function () {
        if (this.ui_size == "M") {
          return true
        } else {
          return false
        }
      }
  },
  created () {
      //console.log('Component created.');
      this.setUISize();
  }
 
}
