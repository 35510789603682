//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DeliveryTrackNoDialog from '@/components/DeliveryTrackNoDialog.vue'
import DeliveryAddressDialog from '@/components/DeliveryAddressDialog.vue'
import DealerAddressDialog from '@/components/DealerAddressDialog.vue'
import store from '../store/index';
import { get } from 'vuex-pathify';

export default {
  name: 'FOrders',
  components: {
    DeliveryAddressDialog,
    DeliveryTrackNoDialog,
    DealerAddressDialog,
  },
  data () {
    return {
      bill_no: "",
      track_no: "",
      deliver_id: "",
      deliver_code: "",
      loading: false,
      delivery_track_no_dialog_show: false,
      delivery_address_dialog_show: false,
      dealer_address_dialog_show: false,
      f_orders_datalist: [],
      f_orders_selected: [],
      f_orders_columns: [
        { name: 'rownum', align: 'center', label: 'ลำดับ', field: 'rownum'},
        { name: 'number_date', align: 'left', label: 'เลขที่บิล/วันที่/สถานะ', field: 'number_date'},
        { name: 'deliver_info_visible', align: 'center', label: '', field: 'deliver_info_visible'},
        { name: 'deliver_info', align: 'left', label: 'ส่งโดย/รหัสจัดส่ง', field: 'deliver_info'},
        { name: 'customer', align: 'left', label: 'สมาชิก/เบอร์โทรติดต่อ', field: 'customer'},
        { name: 'bill_detail', align: 'left', label: 'รายละเอียดสินค้า', field: 'bill_detail'},
        { name: 'total_amount', align: 'right', label: 'ค่าสินค้า', field: 'total_amount'},
        { name: 'delivery_cost', align: 'right', label: 'ค่าจัดส่ง', field: 'delivery_cost'},
        { name: 'total_amount_net_txt', align: 'right', label: 'รวมเงินสุทธิ', field: 'total_amount_net_txt'},
        { name: 'f_deliver_info', align: 'left', label:'ส่งคืนตัวแทนฯ', field: 'f_deliver_info' }
      ],
      my_orders_datalist: [],
      my_orders_selected: [],
      my_orders_columns: [
        { name: 'rownum', align: 'center', label: 'ลำดับ', field: 'rownum'},
        { name: 'number_date', align: 'left', label: 'เลขที่บิล/วันที่/สถานะ', field: 'number_date'},
        { name: 'deliver_info_visible', align: 'center', label: '', field: 'deliver_info_visible'},
        { name: 'deliver_info', align: 'left', label: 'ส่งโดย/รหัสจัดส่ง', field: 'deliver_info'},
        { name: 'customer', align: 'left', label: 'สมาชิก/เบอร์โทรติดต่อ', field: 'customer'},
        { name: 'bill_detail', align: 'left', label: 'รายละเอียดสินค้า', field: 'bill_detail'},
        { name: 'total_amount', align: 'right', label: 'ค่าสินค้า', field: 'total_amount'},
        { name: 'delivery_cost', align: 'right', label: 'ค่าจัดส่ง', field: 'delivery_cost'},
        { name: 'total_amount_net_txt', align: 'right', label: 'รวมเงินสุทธิ', field: 'total_amount_net_txt'},
        { name: 'f_deliver_info', align: 'left', label:'ส่งคืนตัวแทนฯ', field: 'f_deliver_info' }
      ],
      send_orders_datalist: [],
      send_orders_selected: [],
      send_orders_columns: [
        { name: 'rownum', align: 'center', label: 'ลำดับ', field: 'rownum'},
        { name: 'number_date', align: 'left', label: 'เลขที่บิล/วันที่/สถานะ', field: 'number_date'},
        { name: 'deliver_info_visible', align: 'center', label: '', field: 'deliver_info_visible'},
        { name: 'deliver_info', align: 'left', label: 'ส่งโดย/รหัสจัดส่ง', field: 'deliver_info'},
        { name: 'customer', align: 'left', label: 'สมาชิก/เบอร์โทรติดต่อ', field: 'customer'},
        { name: 'bill_detail', align: 'left', label: 'รายละเอียดสินค้า', field: 'bill_detail'},
        { name: 'total_amount', align: 'right', label: 'ค่าสินค้า', field: 'total_amount'},
        { name: 'delivery_cost', align: 'right', label: 'ค่าจัดส่ง', field: 'delivery_cost'},
        { name: 'total_amount_net_txt', align: 'right', label: 'รวมเงินสุทธิ', field: 'total_amount_net_txt'},
        { name: 'f_deliver_info', align: 'left', label:'ส่งคืนตัวแทนฯ', field: 'f_deliver_info' }
      ],
      tab_selected: "f_orders",
      pageID: 1,
      total_record: 0,
      total_page: 0,
      rowsPerFetch: 100,
      pagination: {
        rowsPerPage: 0
      },
    }
  },
  methods: {
    triggerWarning (msg_text) {
      this.$q.notify({
        type: 'warning',
        message: msg_text,
        classes:'app-font'
      })
    },
    triggerPositive (msg_text) {
      this.$q.notify({
        type: 'positive',
        progress: true,
        message: msg_text,
        classes:'app-font'
      });
    },
    triggerNegative (msg_text) {
      this.$q.notify({
        type: 'negative',
        message: msg_text,
        classes:'app-font'
      })
    },
    trackDelivery: function(deliver_id, deliver_code, deliver_url) {
        let self = this;
        let actionUrl = self.url_api + "MemberClient/eStore.Delivery.Track.aspx";
        let windowName = "ตรวจสอบสถานะการจัดส่ง";
        let windowFeatures = "";
        let data = {
                      Authorization: self.jwt_token,
                      deliver_id: deliver_id,
                      deliver_code: deliver_code,
                      deliver_url: deliver_url
                  };
        this.windowOpenWithPost(actionUrl, windowName, windowFeatures, data);
    },
    open_dealer_address_dialog: function(bill_no) {
          //alert("open delivery address dialog");
        this.bill_no = bill_no;
        this.dealer_address_dialog_show = true;
    },
    dealer_address_dialog_hide: function() {
      this.dealer_address_dialog_show = false;
    },
    open_delivery_address_dialog: function(bill_no) {
          //alert("open delivery address dialog");
        this.bill_no = bill_no;
        this.delivery_address_dialog_show = true;
    },
    delivery_address_dialog_hide: function() {
      this.delivery_address_dialog_show = false;
    },
    open_delivery_track_no_dialog: function(bill_no, track_no, deliver_id) {
          this.bill_no = bill_no;
          this.track_no = track_no;
          this.deliver_id = deliver_id;
          this.delivery_track_no_dialog_show = true;
      },
    delivery_track_no_dialog_hide: function() {
      this.delivery_track_no_dialog_show = false;
    },
    changeTab: function (tabName) { 
      this.tab_selected = tabName;
    },
    alertTabName: function () {
      //alert("alertTabName : " +this.tab_selected);
    },
    getDataFOrders: function () {
        var self = this;
        self.loading = true;
        self.rowsPerFetch = self.appRowsPerFetch;
        const params = new URLSearchParams();
        self.axios({
          headers: {
            'Authorization': self.jwt_token
          },
          method: 'post',
          timeout: 0,
          url: self.url_api + 'MemberClient/eStore.FOrders.List.JSON.aspx',
          data: params
        })
        .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let record_count = parseInt(responseDoc.getElementsByTagName('recordcount')[0].textContent);
            if (record_count > 0) {
              self.f_orders_datalist = JSON.parse(self.replaceAll(responseDoc.getElementsByTagName('json')[0].textContent,"\\r","<br>"));
              self.f_orders_selected = [];
            } else {
              self.f_orders_datalist = [];
              self.f_orders_selected = [];
            }
            self.total_record = record_count;
            self.loading = false;
            self.init = true;

            self.$q.loading.hide();
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.f_orders_datalist = [];
            self.f_orders_selected = [];
            self.loading = false;
            self.$q.loading.hide();
        });
    },
    confirm_f_orders() {
          if (this.f_orders_selected.length > 0)
          {
              let self = this;
              this.$q.dialog({
                title: "ยืนยันคำสั่ง",
                message: "ต้องการจัดส่งบิลเลขที่ : " + self.f_orders_selected[0].number,
                persistent: true,
                html: true,
                class: "app-font",
                style: "background-color:#1C81F3; color:#ffffff;",
                ok: {
                  label: "ตกลง",
                  color: "white",
                  outline: true,
                  icon: "check_circle",
                },
                cancel: {
                  label: "ยกเลิก",
                  color: "white",
                  flat: true,
                  icon: "cancel"
                },
              }).onOk(() => { 
                this.$q.loading.show();
                let self = this;
                setTimeout( function() { 
                  self.post_f_orders();
                }, 1000);
                // console.log('>>>> OK')
              }).onOk(() => {
                // console.log('>>>> second OK catcher')
              }).onCancel(() => {
                // console.log('>>>> Cancel')
              }).onDismiss(() => {
                // console.log('I am triggered on both OK and Cancel')
              })
          }      
    },
    post_f_orders() {
            let self = this;
            let number_list = self.f_orders_selected.map(function(item) {
              return item['number'];
            });
            let number_list_string = number_list.join("|");

            const params = new URLSearchParams();
            params.append('number_list', number_list_string);

            self.axios({
              method: "post",
              headers: {
                'Authorization': self.jwt_token
              },
              url: self.url_api + "MemberClient/eStore.FOrders.Update.JSON.aspx",
              data: params
            })
            .then(function (response) {
              let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
              let update_status = responseDoc.getElementsByTagName('update_status')[0].textContent;
              let message = responseDoc.getElementsByTagName('message')[0].textContent;
              if (update_status == "OK") {
                self.triggerPositive(message);
                self.$q.loading.hide();
                self.tab_selected = "my_orders";
              } else {
                self.triggerNegative("ไม่สามารถบันทึกข้อมูลได้");
                self.$q.loading.hide();
                self.getDataFOrders();
              }
            })
            .catch(function (error) {
              self.triggerNegative("ไม่สามารถบันทึกข้อมูลได้");
              self.$q.loading.hide();
              self.getDataFOrders();
              console.log("Error :" + error);
            });
    },
    getDataMyOrders: function () {
        var self = this;
        self.loading = true;
        self.rowsPerFetch = self.appRowsPerFetch;
        const params = new URLSearchParams();
        self.axios({
          headers: {
            'Authorization': self.jwt_token
          },
          method: 'post',
          timeout: 0,
          url: self.url_api + 'MemberClient/eStore.MyOrders.List.JSON.aspx',
          data: params
        })
        .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let record_count = parseInt(responseDoc.getElementsByTagName('recordcount')[0].textContent);
            if (record_count > 0) {
              self.my_orders_datalist = JSON.parse(self.replaceAll(responseDoc.getElementsByTagName('json')[0].textContent,"\\r","<br>"));
              self.my_orders_selected = [];
            } else {
              self.my_orders_datalist = [];
              self.my_orders_selected = [];
            }
            self.total_record = record_count;
            self.loading = false;
            self.$q.loading.hide();
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.my_orders_datalist = [];
            self.my_orders_selected = [];
            self.loading = false;
            self.$q.loading.hide();
        });
    },
    confirm_my_orders() {
          if (this.my_orders_selected.length > 0)
          {
              let self = this;
              this.$q.dialog({
                title: "ยืนยันคำสั่ง",
                message: "จัดส่งบิลเรียบร้อย : " + self.my_orders_selected.length + " บิล",
                persistent: true,
                html: true,
                class: "app-font",
                style: "background-color:#34B900; color:#ffffff;",
                ok: {
                  label: "ตกลง",
                  color: "white",
                  outline: true,
                  icon: "check_circle",
                },
                cancel: {
                  label: "ยกเลิก",
                  color: "white",
                  flat: true,
                  icon: "cancel"
                },
              }).onOk(() => { 
                this.$q.loading.show();
                let self = this;
                setTimeout( function() { 
                  self.post_my_orders();
                }, 1000);
                // console.log('>>>> OK')
              }).onOk(() => {
                // console.log('>>>> second OK catcher')
              }).onCancel(() => {
                // console.log('>>>> Cancel')
              }).onDismiss(() => {
                // console.log('I am triggered on both OK and Cancel')
              })
          }      
    },
    post_my_orders() {
            let self = this;
            let number_list = self.my_orders_selected.map(function(item) {
              return item['number'];
            });
            let number_list_string = number_list.join("|");

            const params = new URLSearchParams();
            params.append('number_list', number_list_string);

            self.axios({
              method: "post",
              headers: {
                'Authorization': self.jwt_token
              },
              url: self.url_api + "MemberClient/eStore.MyOrders.Update.JSON.aspx",
              data: params
            })
            .then(function (response) {
              let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
              let update_status = responseDoc.getElementsByTagName('update_status')[0].textContent;
              let message = responseDoc.getElementsByTagName('message')[0].textContent;
              if (update_status == "OK") {
                self.triggerPositive(message);
                self.$q.loading.hide();
                self.tab_selected = "send_orders";
              } else {
                self.triggerNegative("ไม่สามารถบันทึกข้อมูลได้");
                self.$q.loading.hide();
                self.getDataMyOrders();
              }
            })
            .catch(function (error) {
              self.triggerNegative("ไม่สามารถบันทึกข้อมูลได้");
              self.$q.loading.hide();
              self.getDataMyOrders();
              console.log("Error :" + error);
            });
            self.$q.loading.hide();
    },
    getDataSendOrders: function () {
        var self = this;
        self.loading = true;
        self.rowsPerFetch = self.appRowsPerFetch;
        const params = new URLSearchParams();
        params.append('pagesize', self.rowsPerFetch);
        params.append('pageid', self.pageID);
        self.axios({
          headers: {
            'Authorization': self.jwt_token
          },
          method: 'post',
          timeout: 0,
          url: self.url_api + 'MemberClient/eStore.SendOrders.List.JSON.aspx',
          data: params
        })
        .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let record_count = parseInt(responseDoc.getElementsByTagName('recordcount')[0].textContent);
            let page_count = parseInt(responseDoc.getElementsByTagName('pagecount')[0].textContent);
            
            if (record_count > 0) {
              self.send_orders_datalist = JSON.parse(self.replaceAll(responseDoc.getElementsByTagName('json')[0].textContent,"\\r","<br>"));
              self.send_orders_selected = [];
            } else {
              self.send_orders_datalist = [];
              self.send_orders_selected = [];
            }
            self.total_record = record_count;
            self.total_page = page_count;
            self.loading = false;
            self.$q.loading.hide();
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.send_orders_datalist = [];
            self.send_orders_selected = [];
            self.total_record = 0;
            self.total_page = 0;
            self.loading = false;
            self.$q.loading.hide();
        });
    },
    
  },
  mounted: function () {
    this.getDataFOrders();
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN'),
      session_mtype: get('SESSION_MTYPE'),
      session_id_no: get('SESSION_ID_NO'),
      session_mname: get('SESSION_MCODE_NAME'),
      session_date: get('SESSION_DATE'),
  },
  watch: {
    tab_selected: function() {
        switch (this.tab_selected) {
          case "f_orders":
            this.getDataFOrders();
            break;
          case "my_orders":
            this.getDataMyOrders();
            break;
          case "send_orders":
            this.getDataSendOrders();
            break;
        }
    },
  },
  created () {
    store.set("APP_TOOLBAR_TITLE","จัดส่งสินค้าแทนบริษัท");
  }
}

