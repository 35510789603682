//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify'
import store from '../store/index'
import DistrictDialog from '@/components/DistrictDialog.vue'

export default {
  components: {
    DistrictDialog
  },
  data () {
    return {
      address_district_dialog_show: false,

      address_edit_btn: "",
      address_edit_btn_disable: false,
      address_update_btn: "hidden",

      bonusrec_edit_btn: "",
      bonusrec_edit_btn_disable: false,
      bonusrec_update_btn: "hidden",

      fortune_edit_btn: "",
      fortune_edit_btn_disable: false,
      fortune_update_btn: "hidden",

      input_editable_color: "grey-1",
      
      readonly: true,
      address_editable_color: "grey-1",
      bonusrec_editable_color: "grey-1",
      fortune_editable_color: "grey-1",
      readonly_address: true,
      readonly_bonusrec: true,
      readonly_fortune: true,

      username: "",
      name_t: "",
      mdate: "",
      id_card: "",
      birthday: "",
      sp_code: "",
      sp_name: "",
      up_code: "",
      up_name: "",
      side: "",
      scode: "",
      sname: "",
      rank: "",
      
      ppvtotal: "",
      pv_l: "",
      pv_r: "",

      idaddress1: "",
      idaddress2: "",
      address1: "",
      address2: "",
      provinceList: null,
      provinceOptions: this.provinceList,
      idprovince_selected : {label: "กรุงเทพมหานคร", value: "2"},
      province_selected : {label: "กรุงเทพมหานคร", value: "2"},
      idzip: "",
      zip: "",
      email: "",
      mobile: "",
      telephone: "",
      bonusrecList: [
            {label: "มารับด้วยตนเอง", value: "1" },
            {label: "โอนผ่านบัญชีธนาคาร", value: "2" },
            {label: "ระงับโบนัส", value: "3" },
            {label: "รับเป็น e-Point", value: "4" }
        ],
      bonusrec_selected: {label: "โอนผ่านบัญชีธนาคาร", value: "2" },
      current_bonusrec: "",
      new_bonusrec: "",
      new_bonusrec_type: "",
      bankcode: "",
      bankname: "",
      bank_info: "",
      branch:"",
      acc_no: "",
      acc_name: "",
      fortune: "",
      document: false,
      bookbank: false,
      sms: false,
      mcard: false,
      confirm_msg: "",
    }
  },
  methods: {
      refresh (done) {
        setTimeout(() => {
          this.fetchData();
          done();
        }, 1000)
      },
      confirm_post(data) {
        let self = this;
        switch (data) {
          case "address":
            self.confirm_msg = "<b>ที่อยู่ติดต่อ : </b></br>" 
                        + self.address1 + "</br>" 
                        + self.address2 + "  " 
                        + self.province_selected.label + "     "
                        + self.zip + "</br>"
                        + "<b>เบอร์มือถือ : </b>" +self.mobile + "</br>"
                        + "<b>เบอร์โทร : </b>" +self.mobile + "</br>"
                        + "<b>e-mail : </b>" +self.email + "</br>";
            break;
          case "bonusrec":
            self.confirm_msg = "<b>การรับโบนัส : </b>" 
                        + self.bonusrec_selected.label + "</br>";
            break;
          case "่heir":
            self.confirm_msg = "<b>ผู้รับมรดก : </b>" 
                        + self.fortune + "</br>";
            break;
        }
        this.$q.dialog({
          title: "ยืนยันการการบันทึก",
          message: self.confirm_msg,
          persistent: true,
          html: true,
          class: "app-font",
          style: "background-color:#FFFFFF; color:#000000;",
          ok: {
            label: "ตกลง",
            color: 'positive',
            icon: 'check'
          },
          cancel: {
            label: "ยกเลิก",
            color: 'negative',
            icon: 'close'
          },
        }).onOk(() => {
          switch (data) {
            case "address":
              self.update_address();
              break;
            case "bonusrec":
              self.update_bonusrec();
              break;
            case "่heir":
              self.update_fortune();
              break;
          }
          // console.log('>>>> OK')
        }).onOk(() => {
          // console.log('>>>> second OK catcher')
        }).onCancel(() => {
          // console.log('>>>> Cancel')
        }).onDismiss(() => {
          // console.log('I am triggered on both OK and Cancel')
        })
      },
      district_dialog_hide() {
          this.address_district_dialog_show = false;
      },
      setContactAddress(province, district, subdistrict, zipcode) {
          if (province.length > 0 && district.length > 0 && subdistrict.length > 0) {
            let district_abbr = "อ.";
            let subdistrict_abbr = "ต.";
            if (province == "กรุงเทพฯ" || province == "กรุงเทพมหานคร") {
              district_abbr = "เขต";
              subdistrict_abbr = "แขวง";
            }
            this.address2 =  subdistrict_abbr + subdistrict + " " + district_abbr + district;
            this.zip = zipcode;
            this.setProvinceSelected(province);
          }  
      },
      triggerPositive (msg_text) {
          this.$q.notify({
            type: 'positive',
            progress: true,
            message: msg_text,
            classes:'app-font'
          });
        },
      triggerNegative (msg_text) {
          this.$q.notify({
            type: 'negative',
            message: msg_text,
            classes:'app-font'
          })
      },
      edit_address: function () {
          this.address_edit_btn = "hidden";
          this.address_edit_btn_disable = true;
          this.bonusrec_edit_btn_disable = true;
          this.fortune_edit_btn_disable = true;
          this.address_update_btn = "";
          this.address_editable_color = "yellow-1";
          this.readonly_address = false;
      },
      cancel_update_address: function () {
          this.address_edit_btn = "";
          this.address_edit_btn_disable = false;
          this.bonusrec_edit_btn_disable = false;
          this.fortune_edit_btn_disable = false;
          this.address_update_btn = "hidden";
          this.address_editable_color = "grey-1";
          this.readonly_address = true;
          this.fetchData();
      },
      update_address: function () {
          let self = this;
          const params = new URLSearchParams();
          params.append('address1', self.address1);
          params.append('address2', self.address2);
          params.append('province', self.province_selected.value);
          params.append('zip', self.zip);
          params.append('telephone', self.telephone);
          params.append('mobile', self.mobile);
          params.append('email', self.email);

          self.axios({
            method: "post",
            headers: {
              'Authorization': self.jwt_token
            },
            url: self.url_api + "MemberClient/Member.Update.Profile.JSON.aspx",
            data: params
          })
          .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let update_status = responseDoc.getElementsByTagName('update_status')[0].textContent;
            let message = responseDoc.getElementsByTagName('message')[0].textContent;
            if (update_status == "OK") {
              self.triggerPositive(message);
              self.cancel_update_address();
              self.fetchData();
            } else {
              self.triggerNegative("ไม่สามารถบันทึกข้อมูลได้");
            }
            
          })
          .catch(function (error) {
            self.triggerNegative("ไม่สามารถบันทึกข้อมูลได้");
            console.log("Error :" + error);
          });
      },
      edit_bonusrec: function () {
          this.bonusrec_edit_btn = "hidden";
          this.address_edit_btn_disable = true;
          this.bonusrec_edit_btn_disable = true;
          this.fortune_edit_btn_disable = true;
          this.bonusrec_update_btn = "";
          this.bonusrec_editable_color = "yellow-1";
          this.readonly_bonusrec = false;
      },
      cancel_update_bonusrec: function () {
          this.bonusrec_edit_btn = "";
          this.address_edit_btn_disable = false;
          this.bonusrec_edit_btn_disable = false;
          this.fortune_edit_btn_disable = false;
          this.bonusrec_update_btn = "hidden";
          this.bonusrec_editable_color = "grey-1";
          this.readonly_bonusrec = true;
          this.fetchData();
      }, 
      update_bonusrec: function () {
          let self = this;
          const params = new URLSearchParams();
          params.append('bonusrec', self.bonusrec_selected.value);
          params.append('fortune', self.fortune);
          self.axios({
            method: "post",
            headers: {
              'Authorization': self.jwt_token
            },
            url: self.url_api + "MemberClient/Member.Update.Bonusrec.JSON.aspx",
            data: params
          })
          .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let update_status = responseDoc.getElementsByTagName('update_status')[0].textContent;
            //let message = responseDoc.getElementsByTagName('message')[0].textContent;
            if (update_status == "OK") {
              self.triggerPositive("ทำการบันทึกคำร้องเปลี่ยนแปลงการรับโบนัสแล้ว จะมีผลในวันพรุ่งนี้");
              self.cancel_update_bonusrec();
              self.fetchData();
            } else {
              self.triggerNegative("ไม่สามารถบันทึกข้อมูลได้");
            }
            
          })
          .catch(function (error) {
            self.triggerNegative("ไม่สามารถบันทึกข้อมูลได้");
            console.log("Error :" + error);
          });
      },
      edit_fortune: function () {
          this.fortune_edit_btn = "hidden";
          this.address_edit_btn_disable = true;
          this.bonusrec_edit_btn_disable = true;
          this.fortune_edit_btn_disable = true;          
          this.fortune_update_btn = "";
          this.fortune_editable_color = "yellow-1";
          this.readonly_fortune = false;
      },
      cancel_update_fortune: function () {
          this.fortune_edit_btn = "";
          this.address_edit_btn_disable = false;
          this.bonusrec_edit_btn_disable = false;
          this.fortune_edit_btn_disable = false;
          this.fortune_update_btn = "hidden";
          this.fortune_editable_color = "grey-1";
          this.readonly_fortune = true;
          this.fetchData();
      },
      update_fortune: function() {
          let self = this;
          const params = new URLSearchParams();
          params.append('fortune', self.fortune);
          self.axios({
            method: "post",
            headers: {
              'Authorization': self.jwt_token
            },
            url: self.url_api + "MemberClient/Member.Update.Fortune.JSON.aspx",
            data: params
          })
          .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let update_status = responseDoc.getElementsByTagName('update_status')[0].textContent;
            let message = responseDoc.getElementsByTagName('message')[0].textContent;
            if (update_status == "OK") {
              self.triggerPositive(message);
              self.cancel_update_fortune();
              self.fetchData();
            } else {
              self.triggerNegative("ไม่สามารถบันทึกข้อมูลได้");
            }
            
          })
          .catch(function (error) {
            self.triggerNegative("ไม่สามารถบันทึกข้อมูลได้");
            console.log("Error :" + error);
          });
      },
      fetchProvince: function () {
          let self = this;
          self.axios({
            method: "post",
            headers: {
              'Authorization': self.jwt_token
            },
            url: self.url_api + "MemberClient/Province.ListAll.JSON.aspx"
          })
          .then(function (response) {
            //console.log("province result :" + response.data[0].label);
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            self.provinceList = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
            self.fetchData();
          })
          .catch(function (error) {
            console.log("Error :" + error);
          });
      },
      
      filterFnAutoselect (val, update) {
          // call abort() at any time if you can't retrieve data somehow
          setTimeout(() => {
            update(
              () => {
                if (val === '') {
                  this.provinceOptions = this.provinceList
                }
                else {
                  const needle = val.toLowerCase()
                  this.provinceOptions = this.provinceList.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
                  //console.log("filter result:" + this.provinceOptions[0].label);
                  /*
                  const result = members.filter((jsonOptions) => {
                    return jsonOptions.label > 25
                  })
                  this.options = result*/

                }
              },

              // next function is available in Quasar v1.7.4+;
              // "ref" is the Vue reference to the QSelect
              
              ref => {
                if (val !== '' && ref.options.length > 0 && ref.optionIndex === -1) {
                  ref.moveOptionSelection(1, true) // focus the first selectable option and do not update the input-value
                  ref.toggleOption(ref.options[ref.optionIndex], true) // toggle the focused option
                }
              }
            )
          }, 300)
        },
      abortFilterFn () {
          // console.log('delayed filter aborted')
        },
      setIDProvinceSelected(selected_value) {
        var found = this.provinceList.find(function(element) { 
          return element.label == selected_value; 
        }); 
        this.idprovince_selected = found;
      },     
      setProvinceSelected(selected_value) {
        var found = this.provinceList.find(function(element) { 
          return element.label == selected_value; 
        });
        this.province_selected = found;
      },   
      setBonusrecSelected(selected_value) {
        var found = this.bonusrecList.find(function(element) { 
          return element.value == selected_value; 
        }); 
        this.bonusrec_selected = found;
      },
      fetchData: function () {
        let self = this;
        const params = new URLSearchParams();
        self.axios({
          method: "post",
          headers: {
            'Authorization': self.jwt_token
          },
          url: self.url_api + "MemberClient/member_profile.JSON.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');

          //console.log("Start Map Data");
          //self.username = responseDoc.getElementsByTagName('name_f')[0].textContent;
          self.name_t = responseDoc.getElementsByTagName('name_t')[0].textContent;
          //self.mstatus = responseDoc.getElementsByTagName('mstatus')[0].textContent;
          self.birthday = responseDoc.getElementsByTagName('birthday')[0].textContent;
          self.mdate = responseDoc.getElementsByTagName('mdate')[0].textContent;
          if (self.session_mtype == "M") {
            self.id_card = responseDoc.getElementsByTagName('id_card')[0].textContent;
            self.rank = responseDoc.getElementsByTagName('rank')[0].textContent;
              /*
            self.ppvtotal = self.formatnumber(parseFloat(self.replaceAll(responseDoc.getElementsByTagName('ppvtotal')[0].textContent.toString(),",","")),2);
            self.pv_l = responseDoc.getElementsByTagName('pv_l')[0].textContent;
            self.pv_r = responseDoc.getElementsByTagName('pv_r')[0].textContent;
            self.pv_maintain = responseDoc.getElementsByTagName('pv_maintain')[0].textContent;
            */
            self.idaddress1 = responseDoc.getElementsByTagName('idaddress1')[0].textContent;
            self.idaddress2 = responseDoc.getElementsByTagName('idaddress2')[0].textContent;
            self.idprovince = responseDoc.getElementsByTagName('idprovince')[0].textContent;
             //console.log("Set ID Province Data");
            self.setIDProvinceSelected(self.idprovince);
            self.idzip = responseDoc.getElementsByTagName('idzip')[0].textContent;
            self.telephone = responseDoc.getElementsByTagName('telephone')[0].textContent;
            //console.log("Set Bank Data");
            self.bankcode = responseDoc.getElementsByTagName('bankcode')[0].textContent;
            self.bankname = responseDoc.getElementsByTagName('bname')[0].textContent;
            self.bank_info = self.bankcode + " - " + self.bankname;
            self.branch = responseDoc.getElementsByTagName('branch')[0].textContent;
            self.acc_no = responseDoc.getElementsByTagName('acc_no')[0].textContent;
            self.acc_name = responseDoc.getElementsByTagName('acc_name')[0].textContent;
            //self.acc_type = responseDoc.getElementsByTagName('acc_type')[0].textContent;
            self.sp_code = responseDoc.getElementsByTagName('sp_code')[0].textContent;
            self.sp_name = responseDoc.getElementsByTagName('sp_name')[0].textContent;
            self.up_code = responseDoc.getElementsByTagName('up_code')[0].textContent;
            self.up_name = responseDoc.getElementsByTagName('up_name')[0].textContent;
            self.side = responseDoc.getElementsByTagName('side')[0].textContent;
            self.fortune = responseDoc.getElementsByTagName('fortune')[0].textContent;
          }
          self.address1 = responseDoc.getElementsByTagName('address1')[0].textContent;
          self.address2 = responseDoc.getElementsByTagName('address2')[0].textContent;
          self.province = responseDoc.getElementsByTagName('province')[0].textContent;
          //console.log("Set Province Data");
          self.setProvinceSelected(self.province);
          self.zip = responseDoc.getElementsByTagName('zip')[0].textContent;
          self.email = responseDoc.getElementsByTagName('email')[0].textContent;
          self.mobile = responseDoc.getElementsByTagName('mobile')[0].textContent;
          /*
          self.new_bonusrec = responseDoc.getElementsByTagName('new_bonusrec')[0].textContent;
          switch (self.new_bonusrec) {
            case "1":
              self.new_bonusrec_type = "-> มารับด้วยตนเอง";
              break;
            case "2":
              self.new_bonusrec_type = "-> โอนผ่านบัญชีธนาคาร";
              break;
            case "3":
              self.new_bonusrec_type = "-> ระงับโบนัส";
              break;
            case "4":
              self.new_bonusrec_type = "-> รับเป็น e-Point";
              break;
          }
          self.current_bonusrec = responseDoc.getElementsByTagName('bonusrec_value')[0].textContent;
          self.bonusrec = responseDoc.getElementsByTagName('bonusrec')[0].textContent;
          self.setBonusrecSelected(self.current_bonusrec);
          */

          /*
          self.scode = responseDoc.getElementsByTagName('scode')[0].textContent;
          self.sname = responseDoc.getElementsByTagName('sname')[0].textContent;
          
          let document, bookbank, sms, mcard
          document = responseDoc.getElementsByTagName('document')[0].textContent;
          bookbank = responseDoc.getElementsByTagName('bookbank')[0].textContent;
          sms = responseDoc.getElementsByTagName('sms')[0].textContent;
          mcard = responseDoc.getElementsByTagName('mcard')[0].textContent;

          if (document == "True") { self.document = true } else { self.document = false }
          if (bookbank == "True") { self.bookbank = true } else { self.bookbank = false }
          if (sms == "True") { self.sms = true } else { self.sms = false }
          if (mcard == "True") { self.mcard = true } else { self.mcard = false }
          */
          
          /*
          if ( self.current_bonusrec == "3" || self.new_bonusrec.length > 0 || (self.current_bonusrec == "4" && self.acc_no.replace("-","").length < 10) ) {
            self.bonusrec_edit_btn = "hidden";
            if (self.current_bonusrec == "2" || self.current_bonusrec == "4") {
              self.setBonusrecSelected(self.current_bonusrec);
            }
          } else {
            self.bonusrec_edit_btn = "";
          }
          */
          self.$q.loading.hide();
        })
        .catch(function (error) {
          console.log("Error :" + error);
          self.$q.loading.hide();
        });
      },
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN'),
      session_mtype: get('SESSION_MTYPE'),
  },
  mounted: function () {
      this.fetchProvince();
  },
  created () {
      //console.log('Component created.');
      store.set("APP_TOOLBAR_TITLE","ข้อมูลส่วนตัว : " + this.session_mcode);
      this.$q.loading.show();
      //this.fetchProvince();
  }
}
