import { render, staticRenderFns } from "./PromptPayDialog.vue?vue&type=template&id=2a5ced68&"
import script from "./PromptPayDialog.vue?vue&type=script&lang=js&"
export * from "./PromptPayDialog.vue?vue&type=script&lang=js&"
import style0 from "./PromptPayDialog.vue?vue&type=style&index=0&scope=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QDialog,QCard,QCardSection,QSeparator,QInput,QBtn,QIcon,QUploader,QCardActions,QAvatar,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDialog,QCard,QCardSection,QSeparator,QInput,QBtn,QIcon,QUploader,QCardActions,QAvatar})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
