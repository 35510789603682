//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify';

export default {
  name: 'DeliveryTrackNoDialog',
  props: ["show_status", "bill_no", "track_no", "deliver_id"],
  data () {
    return {
      loading: false,
      fixed: false,
      deliverList: [],
      deliver_selected: "",
      delivery_code: "",
      readOnlyColor: "yellow-2",
    }
  },
  methods: {
      setDeliverSelectedByValue(selected_value) {
          var found = this.deliverList.find(function(element) { 
            return element.value == selected_value; 
          });
          this.deliver_selected = found;
      },  
      fetchDeliver: function () {
        let self = this;
        self.axios({
          method: "post",
          headers: {
            'Authorization': self.jwt_token
          },
          url: self.url_api + "MemberClient/eStore.Billing.Deliver.ListAll.JSON.aspx"
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          self.deliverList = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
          //alert("self.deliver_id : " + self.deliver_id);
          if (self.deliver_id != "") {
             self.setDeliverSelectedByValue(self.deliver_id);
          } else {
             self.deliver_selected = self.deliverList[0]; 
          }
        })
        .catch(function (error) {
          console.log("Error :" + error);
        });
      },
      post_update: function () {
        if (this.delivery_code == null) {
          this.delivery_code = "";
        }
        let self = this;
        self.$q.loading.show();
        self.loading = true;
        const params = new URLSearchParams();
        params.append('bill_no', self.bill_no);
        params.append('deliver_id', self.deliver_selected.value);
        params.append('deliver_code', self.delivery_code);

        self.axios({
          method: 'post',
          headers: {
              'Authorization': self.jwt_token
          },
          timeout: 0,
          url: self.url_api + 'MemberClient/eStore.Delivery.Info.Update.JSON.aspx',
          data: params
        })
        .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let update_status = responseDoc.getElementsByTagName('status')[0].textContent;
            //let message = responseDoc.getElementsByTagName('message')[0].textContent;
            if (update_status == "OK") {
                  self.$q.loading.hide();
                  self.triggerPositive();
            } else {
                  self.triggerNegative();
                  self.$q.loading.hide();
            }
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.loading = false;
            self.triggerNegative();
            self.$q.loading.hide();
        });
      }, 
      triggerCustomRegisteredType2 () {
        // this one overrides some of the original
        // options of the "my-notif" registered type
        this.$q.notify({
          type: 'my-notif',
          icon: 'contactless',
          message: `This notification is using a custom type.`,
          caption: `It overrides the type's default icon and color.`,
          color: 'primary',
          progress: true,
          textColor: 'white'
        })
      },
      triggerPositive () {
        this.$q.notify({
          type: 'positive',
          progress: true,
          message: `บันทึกข้อมูลเรียบร้อย`
        });
        this.fixed = false;
        this.$emit("updated", true);
      },
      triggerNegative () {
        this.$q.notify({
          type: 'negative',
          message: `ไม่สามารถบันทึกข้อมูลได้ `
        })
      },

      triggerWarning (msg_text) {
        this.$q.notify({
          type: 'warning',
          message: msg_text
        })
      },
      restrictInput(event) {
        let allowedKeys = [8, 33, 64, 35, 36, 37, 42]
        let key = event.keyCode;
        if ((key >= 48 && key <= 57) || 
            (key >= 65 && key <= 90) || 
            (key >= 97 && key <= 122) || allowedKeys.includes(key)) {
          //console.log("Restrict Pass key:" + key + " value : " + String.fromCharCode(key));
        } else {
          event.preventDefault();
          //console.log("Restrict Not Pass key:" + key + " value : " + String.fromCharCode(key));
        }
      },
      onHide() {
        //console.log("onHide");
        this.$emit("onHide", "");
        //this.$parent.scode_dialog_show = false;
      }
    /*
    ,
    // when props.ok() gets called
    onOk (data) { },

    // when props.cancel() gets called
    onCancel () { },

    // when we show it to the user
    onShow () { },

    // when it gets hidden
    onHide () { },

    // custom handler
    async choose (okFn, hero) {
      if (this.name.length === 0) {
        this.$q.dialog({
          title: 'Please specify your name!',
          message: `Can't buy tickets without knowing your name.`
        })
      }
      else {
        await okFn()
        this.$q.notify(`Ok ${this.name}, going with ${hero}`)
      }
    }
    */
  },
  mounted () {
      //console.log('mounted.');
      //this.delivery_code = this.track_no;
  },
  watch: {
    show_status: function () {
      //console.log("fixed :" + this.fixed);
      this.fixed = this.show_status;
      this.delivery_code = this.track_no;
      //alert("delivery id:" + this.deliver_id);
      this.fetchDeliver();
      
      //this.new_password = null;
      //this.confirm_password = null;
    }
  },
  computed: {
      jwt_token: get('JWT_TOKEN'),
      url_api: get('URL_API_PATH'),
      session_username: get('SESSION_USERNAME'),
      editableColor: get("EDITABLE_COLOR")
  },
  created () {
    /**
     * The reason we have this here
     * is that the type needs to be
     * registered before using it.
     *
     * The best place would be a boot file instead
     * of a .vue file, otherwise it'll keep on
     * registering it every time your component
     * gets to be used :)
     */

    this.$q.notify.registerType('my-notif', {
      icon: 'announcement',
      progress: true,
      color: 'brown',
      textColor: 'white',
      classes: 'glossy'
    })
  }
 
}
