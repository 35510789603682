//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify';

export default {
  name: 'RegistrationBillDialog',
  props: ["show_status"],
  data () {
    return {
      loading: false,
      mtype: "1",
      total_record: 0,
      total_page: 1,
      total_page_suffix: "",
      rowsPerFetch: 100,
      pagination: {
        rowsPerPage: 0
      },
      pageID: 1,
      findWord: '',
      current_row: 1,
      datalist: [],
      selected: [],
      columns: [
        { name: 'rownum', align: 'center', label: 'ลำดับ', field: 'rownum'},
        { name: 'number', align: 'center', label: 'เลขที่ใบเสร็จ', field: 'number'},
        { name: 'prodid', align: 'center', label: 'รายการ', field: 'prodid'},
        { name: 'prodcode', align: 'center', label: 'รหัสสินค้า', field: 'prodcode'},
        { name: 'q_remain', align: 'center', label: 'จำนวนคงเหลือ', field: 'q_remain'},
        { name: 'id_card', align: 'center', label: 'เลขที่บัตรประชาชน', field: 'id_card'},
        { name: 'name_t', align: 'left', label: 'ชื่อ-นามสกุล', field: 'name_t'},
      ],
      /* 
      customDialogModel: false,
      name: ''
      */
      fixed: false
    }
  },
  methods: {
    previous_page: function() {
      if (parseInt(this.pageID) > 1) {
        this.pageID = parseInt(this.pageID) - 1;
      }
    },
    next_page: function() {
      if (parseInt(this.pageID) < parseInt(this.total_page)) {
        this.pageID = parseInt(this.pageID) + 1;
      }
    },
    requestRowClick: function(selected_row) {
      let rank, position;
      if (this.mtype == "1") {
        rank = "B1";
        position = "02";
      } else {
        if (this.mtype == "3") {
           rank = "B3";
           position = "03";
        }
      }
      //alert("selected id_card: " + selected_row.id_card);
      this.$emit("onSeleted", selected_row, rank, position);
      this.fixed = false;
    },
    fetchDataList: function () {
      let self = this;
      self.loading = true;
      const params = new URLSearchParams();
      params.append('pagesize', self.rowsPerFetch);
      params.append('pageid', self.pageID);
      params.append('orders_by_id_card', self.session_id_no);
      params.append('mtype', self.mtype);
      //console.log("fetch bill list");
      self.axios({
        method: 'post',
        headers: {
          'Authorization': self.jwt_token
        },
        timeout: 0, // Let's say you want to wait at least 4 mins
        url: self.url_api + 'MemberClient/MemberBill.List.JSON.aspx',
        data: params
      })
      .then(function (response) {
        let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
        let record_count = parseInt(responseDoc.getElementsByTagName('recordcount')[0].textContent);
        let page_count = parseInt(responseDoc.getElementsByTagName('pagecount')[0].textContent);
        if (record_count > 0) {
          self.datalist = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
          //self.selected = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent).slice(0, 1);
          self.total_record = record_count;
          self.total_page = page_count;
          self.loading = false;
        } else {
          self.datalist = [];
          self.selected = [];
          self.total_record = 0;
          self.total_page = 1;
          self.loading = false;
        }
      })
      .catch(function (error) {
          console.log("Error :" + error);
          self.loading = false;
      });
    }, 
    onShow() {
      this.fetchDataList();
    },
    onHide() {
      //console.log("onHide");
      this.$emit("onHide", "");
      //this.$parent.scode_dialog_show = false;
    }
    /*
    ,
    // when props.ok() gets called
    onOk (data) { },

    // when props.cancel() gets called
    onCancel () { },

    // when we show it to the user
    onShow () { },

    // when it gets hidden
    onHide () { },

    // custom handler
    async choose (okFn, hero) {
      if (this.name.length === 0) {
        this.$q.dialog({
          title: 'Please specify your name!',
          message: `Can't buy tickets without knowing your name.`
        })
      }
      else {
        await okFn()
        this.$q.notify(`Ok ${this.name}, going with ${hero}`)
      }
    }
    */
  },
  mounted () {
      //console.log('Center Dialog mounted.');
      //this.fetchDataList();
  },
  watch: {
    show_status: function () {
      //console.log("fixed :" + this.fixed);
      this.fixed = this.show_status;
      /*
      if (this.show_status) {
        this.selected.splice(0,1);
      }
      */
    },
    mtype : function () {
      this.fetchDataList();
    },
    pageID: function () {
      this.fetchDataList();
    }
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      session_mcode: get('SESSION_MCODE_LOGIN'),
      session_id_no: get('SESSION_ID_NO')
  }
 
}
