//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


//import { openURL } from 'quasar'
import { get } from 'vuex-pathify'
import store from '../store/index'
import MbscDatePicker from '@/components/MbscDatePicker.vue'
import BonusdaMpbDetailDialog from '@/components/BonusdaMpbDetailDialog.vue'
import BonusdaFsDetailDialog from '@/components/BonusdaFsDetailDialog.vue'

export default {
  components: {
    MbscDatePicker,
    BonusdaMpbDetailDialog,
    BonusdaFsDetailDialog
  },
  data () {
    return {
      selected_date: "",
      bonusda_mpb_detail_dialog_show: false,
      bonusda_fs_detail_dialog_show: false,
      all_mcode: false,
      mcode: "",
      init: false,
      from_bonus_date:"",
      to_bonus_date:"",
      loading: false,
      query:"1",
      total_record: 0,
      total_page: 1,
      rowsPerFetch: 100,
      pagination: {
        rowsPerPage: 0
      },
      pageID: 1,
      datalist: [],
      selected: [],
      columns: [
        { name: 'rownum', align: 'center', label: 'ลำดับ', field: 'rownum'},
        { name: 'date', align: 'center', label: 'วันที่', field: 'date'},
        //{ name: 'mcode', align: 'center', label: 'รหัสสมาชิก', field: 'mcode'},
        //{ name: 'rank', align: 'center', label: 'ตำแหน่ง', field: 'rank'},
        /*{ name: 'step', align: 'center', label: 'STEP', field: 'step'},*/
        { name: 'bonus_01', align: 'right', label: 'MPB 50%', field: 'bonus_01' },
        { name: 'bonus_02', align: 'right', label: 'Faststart', field: 'bonus_02' },
        { name: 'total_bonus', align: 'right', label: 'รวมโบนัส', field: 'total_bonus' },
        //{ name: 'service_charge', align: 'right', label: 'ค่าดำเนินการ', field: 'service_charge'},
        { name: 'tax_bonus', align: 'right', label: 'หักภาษี', field: 'tax_bonus'},
        { name: 'total_bonus_net', align: 'right', label: 'รวมสุทธิ', field: 'total_bonus_net'},
      ],
    }
  },
  methods: {
      refresh (done) {
        setTimeout(() => {
          this.fetchBonusDate();
          done();
        }, 1000)
      },
      /*
      exportPDF: function(bonus_date) {
          let self = this;
          openURL(self.url_api + '../pdf/Report.Daily.Bonus.Detail.GT50.ByMcode.PDF.aspx?date='+ bonus_date);    
      },
      */
      open_bonusda_mpb_detail_dialog(date) {
        this.selected_date = date;
        this.bonusda_mpb_detail_dialog_show = true;
      },
      bonusda_mpb_detail_dialog_hide() {
        this.bonusda_mpb_detail_dialog_show = false;
      },
      open_bonusda_fs_detail_dialog(date) {
        this.selected_date = date;
        this.bonusda_fs_detail_dialog_show = true;
      },
      bonusda_fs_detail_dialog_hide() {
        this.bonusda_fs_detail_dialog_show = false;
      },
      exportPDF: function(bonus_date) {
          let self = this;
          let actionUrl = self.url_api + "pdf/Report.Daily.Bonus.Detail.PDF.aspx";
          let windowName = "รายละเอียดโบนัสรายวัน";
          let windowFeatures = "";
          let data = {
                        Authorization: self.jwt_token,
                        date: bonus_date,
                        mcode: this.session_mcode
                     };
          this.windowOpenWithPost(actionUrl, windowName, windowFeatures, data);
      },
      /*
      exportExcel: function() {
          let self = this;
          openURL(self.url_api + 'Bonus/Report.Daily.Bonus.ByPeriodDate.Excel.aspx')    
      },*/
      requestRowClick: function(selected_mcode, selected_row) {
          if (this.selected.mcode !== selected_mcode) {
            this.selected.splice(0,1);
            this.selected.push(selected_row);
          }
      },
      fetchBonusDate: function () {
        const params = new URLSearchParams();
        //params.append("mcode", this.session_mcode);
        let self = this;
        self.axios({
          method: "post",
          headers: {
            'Authorization': self.jwt_token
          },
          url: self.url_api + "MemberClient/GetBonusDateRange.JSON.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          let start_date = responseDoc.getElementsByTagName('startdate')[0].textContent;
          let end_date = responseDoc.getElementsByTagName('enddate')[0].textContent;
          //console.log("start_date :" + start_date + " end_date : " + end_date);
          self.from_bonus_date = start_date;
          self.to_bonus_date = end_date;
          self.getData();
        })
        .catch(function (error) {
          console.log("Error :" + error);
        });
      },
      updateDate(e, title) {
        //console.log("update mdate to :" + e);
        switch (title) {
          case "ตั้งแต่วันที่":
            this.from_bonus_date = e;
            break;
          case "ถึงวันที่":
            this.to_bonus_date = e;
            break;  
        }
      },
      getData: function () {
        var self = this;
        if (self.init) {
          self.loading = true;
        }
        self.rowsPerFetch = self.appRowsPerFetch;
        const params = new URLSearchParams();
        params.append('pagesize', self.rowsPerFetch);
        params.append('pageid', self.pageID);
        params.append('from_date', self.from_bonus_date);
        params.append('to_date', self.to_bonus_date);
        params.append('mcode', self.mcode);

        self.axios({
          method: 'post',
          headers: {
            'Authorization': self.jwt_token
          },
          timeout: 0, // Let's say you want to wait at least 4 mins,
          url: self.url_api + 'MemberClient/GetBonusDaily.JSON.aspx',
          data: params
        })
        .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let record_count = parseInt(responseDoc.getElementsByTagName('recordcount')[0].textContent);
            let page_count = parseInt(responseDoc.getElementsByTagName('pagecount')[0].textContent);
            
            /*
            console.log(responseDoc.getElementsByTagName('json')[0].textContent);
            console.log("record count " + record_count.toString());
            console.log("page count " + responseDoc.getElementsByTagName('pagecount')[0].textContent);
            */

            if (record_count > 0) {
              self.datalist = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
              self.selected = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent).slice(0, 1);
            } else {
              self.datalist = [];
              self.selected = [];
            }
            self.total_record = record_count;
            self.total_page = page_count;
            self.loading = false;
            self.init = true;
            self.$q.loading.hide();
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.datalist = [];
            self.selected = [];
            self.loading = false;
            self.$q.loading.hide();
        });
      }
  },
  mounted: function () {
      /*
      window.addEventListener("keyup", e => {
        switch (e.keyCode) {
          case 38:
            alert(e.keyCode);
            break;
          case 40:
            alert(e.keyCode);
            break;
        }    
        /*
        if (String.fromCharCode(e.keyCode)=="&") {

        } else {
            if (String.fromCharCode(e.keyCode)=="(") {

            }
        }
      });*/
  
      //var self = this;
      //console.log('API URL:' + self.url_api + 'Member/Member.View.JSON.aspx');
      
      //console.log('Component mounted.');
      this.fetchBonusDate();
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN')
  },
  watch: {
    all_mcode: function () {
      if (this.all_mcode) {
        this.mcode = "%";
      } else {
        this.mcode = this.session_mcode;
      }
      this.getData();
    },
    pageID: function () {
      if (this.init) {
        this.getData();
      }  
    },
    from_bonus_date: function () {
      if (this.init) {
        this.getData();
      }  
    },
    to_bonus_date: function () {
      if (this.init) {
        this.getData();
      }  
    },
  },
  created () {
      store.set("APP_TOOLBAR_TITLE","โบนัสรายวัน : " + this.session_mcode);
      this.mcode = this.session_mcode;
      this.$q.loading.show();
      //console.log('Component created.');
  }    
}   
