import Vue from 'vue'
import Vuex from 'vuex'
import pathify from 'vuex-pathify'
import { make } from 'vuex-pathify';

pathify.options.mapping = 'standard'
pathify.options.deep = 2

Vue.use(Vuex)

const state = {
    JWT_TOKEN: "",
    URL_APP_PATH: "/PLMember/",
    URL_API_PATH: window.location.href.substr(0, 5).replace(":","") + "://" + window.location.hostname + "/PLMember/api/",
    URL_SHUTDOWN_STATUS: window.location.href.substr(0, 5).replace(":","") + "://" + window.location.hostname + "/PLAdmin/api/System/",
    APP_TOOLBAR_CLASS: "display",
    APP_TOOLBAR_TITLE: "",
    APP_ROW_PER_FETCH: 2000,
    SESSION_ID_NO: "",
    SESSION_MCODE_LOGIN:"",
    SESSION_MCODE_NAME:"",
    SESSION_MDATE:"",
    SESSION_MTYPE:"",
    SESSION_UP_LEVEL:"",
    SESSION_SP_LEVEL:"",
    SESSION_POSITION:"",
    SESSION_AGENT:"",
    SESSION_SCODE:"",
    SESSION_SNAME:"",
    SESSION_USERNAME:"",
    SESSION_SESSIONID: "",
    SESSION_DATE:"",
    SESSION_EXPDATE:"",
    OFFICE_CONTROL_USER: "",
    
    APP_SHUTDOWN_VALUE:"",
    APP_SHUTDOWN_MSG:"",
    APP_LANG_UI: "0",
    APP_MCODE_LENGTH: 7,

    EDITABLE_COLOR:"",
    INIT_UP_CODE:"",
    INIT_SIDE:""
};

// You don't even need getters, pathify will use the store data directly!
// Though if you want, it can generate them for you with `make.getters(state)`

// Same for mutations and actions. (Uses the SET_* format, but this is configurable.)
const mutations = make.mutations(state);

export default new Vuex.Store({
  plugins: [pathify.plugin],
  state,
  mutations
})
