import { render, staticRenderFns } from "./Main.vue?vue&type=template&id=0b81717c&"
import script from "./Main.vue?vue&type=script&lang=js&"
export * from "./Main.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QPullToRefresh,QCard,QCardSection,QMarkupTable,QBtn,QIcon,QSelect,QTable,QInnerLoading,QTr,QTh,QTd} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QPullToRefresh,QCard,QCardSection,QMarkupTable,QBtn,QIcon,QSelect,QTable,QInnerLoading,QTr,QTh,QTd})
