//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify'

export default {
  name: "EStoreAddToCartDialogDialog",
  props: ["show_status","item_info"],

  data () {
    return {
      full_width_boolean: false,
      dialog_style: "",
      submit_btn_disable: false,
      isPwd: true,
      fixed: false,
      loading: false,
      quantity: 0,
      total_pv: 0,
      total_mv: 0,
      total_amount: 0,
      item_info_local: null,
      jsonPrice_local: [
          { 
              from_qty : 0,
              price : 0,
              total_price : 0,
              delivery_cost : 0,
              total_amount : 0
          }
      ],
      pagination: {
        rowsPerPage: 0
      },
      selected: [],
      columns: [
        { name: 'from_qty', align: 'right', label: 'จำนวน(แพ็ค)', field: row => row.from_qty, format: val => `${this.formatnumber(val,0)}`/*, sortable: true */ },
        { name: 'price', align: 'center', label: 'ราคา/แพ็ค', field: 'price' /*, sortable: true */ },
        { name: 'total_price', label: 'ค่าสินค้า', field: row => row.total_price, format: val => `${this.formatnumber(val,0)}`},
        /*
        { name: 'delivery_cost', label: 'ค่าจัดส่ง', field: row => row.delivery_cost, format: val => `${this.formatnumber(val,0)}`},
        { name: 'total_amount', label: 'รวมสุทธิ', field: row => row.total_amount, format: val => `${this.formatnumber(val,0)}`},
        */
      ],
    }
  },
  methods: {
      requestRowClick: function(selected_mcode, selected_row) {
          this.selected.splice(0,1);
          this.selected.push(selected_row);
      },
      add_one() {
        let case_ratio;
        case_ratio = parseInt(this.item_info_local.case_ratio);
        if (parseInt(this.quantity) <= 1000) {
          //alert("case_ratio : " + case_ratio);
          this.quantity = parseInt(this.quantity) + parseInt(case_ratio);
          //alert("this.quantity : " + this.quantity);
        }  
      },
      reset() {
        this.quantity = 0;
      },
      remove_one() {
        let case_ratio;
        case_ratio = parseInt(this.item_info_local.case_ratio);
        if (parseInt(this.quantity) >= case_ratio) {
          this.quantity = parseInt(this.quantity) - parseInt(case_ratio);
        }
      },
      setDialogSize() {
        //if (this.$q.screen.width > 1023) { 
        if (this.$q.screen.width > 800) {
          //console.log('screen large');
          this.full_width_boolean = false;
          this.dialog_style = "background-color:#f5fafe; width: 520px; max-width: 42vw;";
        }
        else {
          //console.log('screen small');
          this.full_width_boolean = true;
          this.dialog_style = "height:450px;";
        }
      },
      formatnumber(num, decimal) {
        return num.toFixed(decimal).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
      },
      triggerPositive (msg_text) {
        this.$q.notify({
          type: 'positive',
          progress: true,
          message: msg_text,
          classes:'app-font'
        });
      },
      triggerNegative (msg_text) {
        this.$q.notify({
          type: 'negative',
          message: msg_text,
          classes:'app-font'
        })
      },
      clearForm: function() {
        this.quantity = 0;
      },
      onHide() {
        //console.log("onHide");
        this.quantity = 0;
        this.$emit("onHide", "");
      },
      onShow() {
        this.total_pv = this.formatnumber(parseFloat(this.replaceAll(this.item_info_local.pv,",","")) * parseInt(this.quantity),2);
        this.total_amount = this.formatnumber(parseFloat(this.replaceAll(this.item_info_local.price,",","")) * parseInt(this.quantity),2);
        //this.$refs.qty_input.focus();
      },
      postData: function () {
        var self = this;

        if (self.quantity == 0) {
          return
        }

        self.submit_btn_disable = true;

        self.loading = true;
        const params = new URLSearchParams();
        params.append('mtype', self.session_mtype);
        params.append('number', self.item_info.cart_no);
        params.append('bill_date', self.session_date);
        params.append('prodcode', self.item_info.prodcode);
        params.append('quantity', self.quantity);
        self.axios({
          headers: {
            'Authorization': self.jwt_token
          },
          method: 'post',
          timeout: 0,
          url: self.url_api + 'MemberClient/eStore.Billing.Temp.InsertItem.JSON.aspx',
          data: params
        })
        .then(function (response) {
            //console.log(response);
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let result = responseDoc.getElementsByTagName('status')[0].textContent;
            let result_txt = responseDoc.getElementsByTagName('message')[0].textContent;
            if (result == "OK") {
              //self.triggerPositive(result_txt);
              self.clearForm();
              self.fixed = false;
            } else {
              self.triggerNegative(result_txt);
            }
            self.loading = false;
            self.$q.loading.hide();
            self.submit_btn_disable = false;
            
            if (result == "OK") {
              self.$emit("onAddNewItem", "");
            }
            
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.triggerNegative("ไม่สามารถบันทึกข้อมูลได้");
            self.loading = false;
            self.$q.loading.hide();
            self.submit_btn_disable = false;
        });
       
      },
      getPrice: function (jsonPriceList, qty) {
          let price = 0;
          if (jsonPriceList.length > 0) {
              price = jsonPriceList[0].price;
              for (let i = 0; i < jsonPriceList.length; i++) {
                if (qty >= jsonPriceList[i].from_qty && qty <= jsonPriceList[i].to_qty) {
                    price = jsonPriceList[i].price;
                    break;
                }
              }
              
          }
          return price;
      }
  },
  mounted () {
      //console.log('EStoreAddToCard Dialog mounted.');
  },
  watch: {
    show_status: function () {
      //console.log("fixed :" + this.fixed);
      this.fixed = this.show_status;
      this.item_info_local = this.item_info;
      try {
          this.jsonPrice_local = this.item_info_local.jsonPrice;
          if (this.jsonPrice_local[0].from_qty == 1) {
            this.jsonPrice_local.shift();
          }
      } catch {
          console.log("Error EstoreAddToCartDialog jsonPrice");
      }
    },
    "$q.screen.width"() {
      this.setDialogSize();
    },
    quantity: function() {
      let price, total_amount;
      if (this.quantity < 0) {
          this.quantity = 0;
      }
      this.total_pv = this.formatnumber(parseFloat(this.replaceAll(this.item_info_local.pv,",","")) * parseInt(this.quantity),2);
      if (this.jsonPrice_local.length > 0) {
        price = this.getPrice(this.jsonPrice_local, this.quantity);
      } else {
        price = 0;
      }
      this.item_info_local.price = this.formatnumber(price,2);
      total_amount = parseFloat(price) * parseInt(this.quantity)
      this.total_amount = this.formatnumber(total_amount,2);

      var found = this.jsonPrice_local.find(function(element) { 
          return element.price == price; 
      });
      
      this.selected.splice(0,1);
      this.selected.push(found);
      //this.total_amount = this.formatnumber(parseFloat(this.replaceAll(this.item_info_local.price,",","")) * parseInt(this.quantity),2);
      /*
      this.total_pv = this.formatnumber(parseFloat(this.replaceAll(this.item_info_local.pv,",","")) * parseInt(this.quantity),2);
      this.total_amount = this.formatnumber(parseFloat(this.replaceAll(this.item_info_local.price,",","")) * parseInt(this.quantity),2);
      */
    }
  },
  computed: {
      bank_info: function () {
        return this.bank_code + " - "  + this.bank_name;
      },
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN'),
      session_date: get('SESSION_DATE'),
      session_mtype: get('SESSION_MTYPE')
  },
  created () {
      //console.log('Component created.');
      this.setDialogSize()
      //this.mcode_current = this.mcode_start;
      //this.fetchPosition();
  }    

}
