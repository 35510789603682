//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import store from '../store/index'
import { get } from 'vuex-pathify'
import DistrictDialog from '@/components/DistrictDialog.vue'
import AddressListDialog from '@/components/AddressListDialog.vue'
//import NewMemberListDialog from '@/components/NewMemberListDialog.vue'
export default {
  components: {
    DistrictDialog,
    AddressListDialog,
    //NewMemberListDialog
  },
  name: 'EStoreCart',
  props: ['cart_info'],
  data() {
    return {
      ordersType: "",
      ui_size: "",
      address_list_dialog_show: false,
      new_member_list_dialog_show: false,
      address_district_dialog_show: false,
      bill_no : "",
      isPwd: true,
      pagination: {
        rowsPerPage: 0
      },
      loading: false,
      query:"1",
      branchList: null,
      datalist: [],
      selected: [],
      columns: [
        { name: 'row_num', align: 'center', label: 'ลำดับ', field: 'row_num'},
        { name: 'prodcode', align: 'left', label: 'รหัสสินค้า', field: 'prodcode'},
        { name: 'prodname', align: 'left', label: 'ชื่อสินค้า', field: 'prodname'},
        /*{ name: 'pv', align: 'right', label: 'Pt', field: 'pv'},*/
        { name: 'unit_price', align: 'right', label: 'ราคา', field: 'unit_price'},
        { name: 'quantity', align: 'right', label: 'จำนวน', field: 'quantity'},
        /*{ name: 'total_pv', align: 'right', label: 'รวม Pt', field: 'total_pv'},*/
        { name: 'price', align: 'right', label: 'ค่าสินค้า', field: 'price'},
        { name: 'delivery_cost', align: 'right', label: 'ค่าจัดส่ง', field: 'delivery_cost'},
      ],
      columns_mobile: [
        { name: 'prodname', align: 'left', label: 'รายละเอียดสินค้า', field: 'prodname'},
      ],
      delivery_cost_current:0,
      delivery_cost: 0,
      total_amount: 0,
      total_pv: 0,
      total_items: 0,
      no_delivery_item_count: 0,
      provinceList: null,
      provinceOptions: this.provinceList,
      /*
      for_id_no: "",
      for_mname: "",
      */
      maddress_btn: "",
      caddress_btn: "hidden",
      delivery_by_disable: false,
      submit_btn_disable: true,
      cart_info_local : {
                    cart_no: "",
                    type: "1",
                    ordersType: "M",
                    for_id_no: "",
                    for_mname: "",
                    delivery_by: "1",
                    branch_selected: "", 
                    receiver_name: "",
                    address1: "",
                    address2: "",
                    province_selected: "", 
                    zipcode: "",
                    phone_no: "",
                    mobile_no: "",
                    district_id: "",
                    province_id: "",
                  }
    }
  },
  methods: {
    refresh (done) {
        setTimeout(() => {
          this.fetchBillInfo();
          done();
        }, 1000)
    },
    setUISize() {
        //if (this.$q.screen.width > 1023) { 
        if (this.$q.screen.width > 800) {
          //console.log('screen large');
          this.ui_size = "D";
        }
        else {
          //console.log('screen small');
          this.ui_size = "M";
        }
    },
    /*
    mcode_selected: function (selected) {
        this.for_id_no = selected.mcode;
        this.fetchMcodeName();
    },
    openMemberDialog: function() {
        this.member_list_dialog_show = true;
        this.$refs.member_list_dialog_ref.fetchDataList();
    },
    member_list_dialog_hide() {
        this.member_list_dialog_show = false;
    },
    */
    new_member_list_dialog_hide() {
      this.new_member_list_dialog_show = false;
    },
    /*
    setIDNo(selected) {
      this.cart_info_local.for_id_no = selected.id_card;
      this.cart_info_local.for_mname = selected.name_t;
    },
    */
    address_list_dialog_hide() {
      this.address_list_dialog_show = false;
    },
    district_dialog_hide() {
      this.address_district_dialog_show = false;
    },
    setAddressData(area) {
        this.cart_info_local.receiver_name = area.receiver;
        this.cart_info_local.address1 = area.address1;
        this.cart_info_local.address2 = area.address2;
        this.cart_info_local.zipcode = area.zipcode;
        this.cart_info_local.phone_no = area.mobile;
        /*
        alert("area.district_id: " + area.district_id);
        alert("area.province_id: " + area.province_id);
        */
        this.cart_info_local.district_id = area.district_id;
        this.cart_info_local.province_id = area.province_id;
        this.setProvinceSelected(area.province);
    },
    setContactAddress(area) {
      if (area.province_name.length > 0 && area.district_name.length > 0 && area.subdistrict_name.length > 0) {
          //alert("setContactAddress district_id:" + area.district_id + " province_id :" + area.province_id );
          if (area.province_name.length > 0 && area.district_name.length > 0 && area.subdistrict_name.length > 0) {
            let district_abbr = "อ.";
            let subdistrict_abbr = "ต.";
            if (area.province_name == "กรุงเทพฯ" || area.province_name == "กรุงเทพมหานคร") {
              district_abbr = "เขต";
              subdistrict_abbr = "แขวง";
            }
            //alert("address2 : " + subdistrict_abbr + area.subdistrict_name + " " + district_abbr + area.district_name)
            this.cart_info_local.address2 =  subdistrict_abbr + area.subdistrict_name + " " + district_abbr + area.district_name;
            this.cart_info_local.zipcode = area.zipcode;
            /*
            alert("area.district_id: " + area.district_id);
            alert("area.province_id: " + area.province_id);
            */
            this.cart_info_local.district_id = area.district_id;
            this.cart_info_local.province_id = area.province_id;
            this.setProvinceSelected(area.province_name);
          }
          
      }
    },
    
    confirm_post() {
      let self = this;
      let delivery_method = "การรับสินค้า : <b>";
      if (self.cart_info_local.delivery_by == "1") {
        delivery_method = delivery_method + "ที่อยู่จัดส่ง ><b><br>" + 
                          " ผู้รับ : " + self.cart_info_local.receiver_name + "&nbsp;&nbsp;(โทร : " + self.cart_info_local.phone_no +")</br>" + 
                          " ที่อยู่ : " + self.cart_info_local.address1 + "" + 
                          "&nbsp;&nbsp;" + self.cart_info_local.address2 + 
                          "&nbsp;" + self.cart_info_local.province_selected.label + "&nbsp;&nbsp;"+ self.cart_info_local.zipcode +"</br>"
      } else {
        delivery_method = delivery_method + "มารับด้วยตนเอง <b>"
      }
      this.$q.dialog({
        title: "ยืนยันการสั่งซื้อสินค้า",
        message: " ผู้ซื้อ : <b>" + self.session_mcode + " - " + self.cart_info_local.for_mname + "</b></br>" +
                 " PV : <b>" + self.formatnumber(self.total_pv,2) + "</b></br>" + 
                 " ค่าสินค้า : <b>" + self.formatnumber(self.total_amount,2) + "</b></br>" + 
                 " ค่าจัดส่ง : <b>" + self.formatnumber(self.delivery_cost,2) + "</b></br>" + 
                 " ค่าสินค้า + ค่าจัดส่ง : <b>" + self.formatnumber(self.total_amount_net,2) + "</b></br>" + 
                 delivery_method,
        persistent: true,
        html: true,
        class: "app-font",
        style: "background-color:#E8F5E9; color:#000000;",
        ok: {
          label: "ตกลง",
          color: 'positive'
        },
        cancel: {
          label: "ยกเลิก"
        },
      }).onOk(() => {
        this.post_cart();
        // console.log('>>>> OK')
      }).onOk(() => {
        // console.log('>>>> second OK catcher')
      }).onCancel(() => {
        // console.log('>>>> Cancel')
      }).onDismiss(() => {
        // console.log('I am triggered on both OK and Cancel')
      })
    },
    post_cart() {
        let self = this;
        //alert("post for_id_no : " + self.cart_info_local.for_id_no);
        
        const params = new URLSearchParams();
        params.append('number', self.cart_info_local.cart_no);
        params.append('address1', self.cart_info_local.address1);
        params.append('address2', self.cart_info_local.address2);
        params.append('province', self.cart_info_local.province_selected.label);
        params.append('zip', self.cart_info_local.zipcode);
        params.append('mobile', self.cart_info_local.phone_no);
        params.append('receive_by', self.cart_info_local.delivery_by);
        params.append('delivery', self.delivery_cost);
        params.append('for_id_no', self.cart_info_local.for_id_no);
        params.append('receiver', self.cart_info_local.receiver_name);
        params.append('receiving_location',self.cart_info_local.branch_selected.data);
        params.append('district_id', self.cart_info_local.district_id );
        params.append('province_id',self.cart_info_local.province_id);
        self.axios({
          headers: {
            'Authorization': self.jwt_token
          },  
          method: "post",
          url: self.url_api + "MemberClient/eStore.Billing.Temp.Post.JSON.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          let result = responseDoc.getElementsByTagName('result_value')[0].textContent;
          let result_txt = responseDoc.getElementsByTagName('result_txt')[0].textContent;
          let bill_no = responseDoc.getElementsByTagName('bill_no')[0].textContent;
          if (result == "1") {
            self.triggerPositive("เลขที่บิลของคุณคือ : " + bill_no + " กดที่ไอคอนคิวอาร์โค๊ดเพื่อทำการชำระเงิน");
            self.cart_info_local.cart_no = "";
            //self.$emit("updateCartInfo",self.cart_info_local);
            self.$emit("changeTab","history");
          } else {
            self.triggerNegative(result_txt);
          }

        })
        .catch(function (error) {
          self.triggerNegative("ไม่สามารถบันทึกรายการได้");
          console.log("Error :" + error);
        });

    },
    validate_form() {
      if (parseFloat(this.total_amount) > 0) {
        if (this.cart_info_local.delivery_by=="1") {
            let delivery_address_valid = true;
            let error_message = "กรุณาตรวจสอบ ";
            let error_detail = "";
            if (this.cart_info_local.receiver_name.length < 5) {
              delivery_address_valid = false;
              error_detail = error_detail + " ชื่อผู้รับ";
            }
            if (this.cart_info_local.address1.length < 5) {
              delivery_address_valid = false;
              error_detail = error_detail + " ที่อยู่สำหรับจัดส่ง";
            }
            if (this.cart_info_local.zipcode.length < 5) {
              delivery_address_valid = false;
              error_detail = error_detail + " รหัสไปรษณีย์";
            }
            if (this.cart_info_local.phone_no.length < 7) {
              delivery_address_valid = false;
              error_detail = error_detail + " เบอร์โทรติดต่อ";
            }
            if (error_detail.length > 0) {
              this.triggerNegative(error_message + error_detail);
            }
            if (delivery_address_valid) {
              this.submit_btn_disable = false;
            } else {
              this.submit_btn_disable = true;
            }
        } else {
            if (this.cart_info_local.delivery_by=="2") {
              this.submit_btn_disable = false;
            } else {
              this.submit_btn_disable = true;
            }
        }
      } else {
        this.submit_btn_disable = true;
      }
    },
    confirm_remove(item) {
      this.$q.dialog({
        title: "ยืนยันการลบรายการ",
        message: " รายการที่ : <b>" + item.row_num + "</b></br>" + 
                 " รหัสสินค้า : <b>" + item.prodcode +"</b></br>" + 
                 " สินค้า : <b>" + item.prodname + "</b></br>" + 
                 " ราคา/หน่วย : <b>" + item.unit_price + "</b>&nbsp;บาท&nbsp;&nbsp;&nbsp;" + 
                 " จำนวน : <b>" + item.quantity + "</b></br>" + 
                 " รวมยอด : <b>" + item.price + "</b>",
        persistent: true,
        html: true,
        class: "app-font",
        style: "border: 1px solid #EE4E2D; background-color:#FFEEEA; color:#EE4E2D;",
        ok: {
          label: "ตกลง",
          color: 'positive'
        },
        cancel: {
          label: "ยกเลิก"
        },
      }).onOk(() => {
        this.removeItem(item.id);
        // console.log('>>>> OK')
      }).onOk(() => {
        // console.log('>>>> second OK catcher')
      }).onCancel(() => {
        // console.log('>>>> Cancel')
      }).onDismiss(() => {
        // console.log('I am triggered on both OK and Cancel')
      })
    },
    removeItemAll: function () {
        let self = this;
        const params = new URLSearchParams();
        params.append('number', self.cart_info_local.cart_no);
        params.append('orders_type', self.cart_info_local.ordersType);
        self.axios({
          headers: {
            'Authorization': self.jwt_token
          },  
          method: "post",
          url: self.url_api + "MemberClient/eStore.Billing.Temp.DeleteItemAll.JSON.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          let result = responseDoc.getElementsByTagName('update_status')[0].textContent;
          let result_txt = responseDoc.getElementsByTagName('message')[0].textContent;
          if (result == "OK") {
            //self.triggerPositive(result_txt);
            self.fetchBillInfo();
          } else {
            self.triggerNegative(result_txt);
          }
        })
        .catch(function (error) {
          self.triggerNegative("ไม่สามารถทำการลบรายการ");
          console.log("Error :" + error);
        });
    },
    removeItem: function (id) {
        let self = this;
        const params = new URLSearchParams();
        params.append('number', self.cart_info_local.cart_no);
        params.append('id',id)
        self.axios({
          headers: {
            'Authorization': self.jwt_token
          },  
          method: "post",
          url: self.url_api + "MemberClient/eStore.Billing.Temp.DeleteItem.JSON.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          let result = responseDoc.getElementsByTagName('update_status')[0].textContent;
          let result_txt = responseDoc.getElementsByTagName('message')[0].textContent;
          if (result == "OK") {
            //self.triggerPositive(result_txt);
            self.fetchBillInfo();
          } else {
            self.triggerNegative(result_txt);
          }
        })
        .catch(function (error) {
          self.triggerNegative("ไม่สามารถทำการลบรายการ");
          console.log("Error :" + error);
        });
    },
    triggerPositive (msg_text) {
        this.$q.notify({
          type: 'positive',
          progress: true,
          message: msg_text,
          classes:'app-font'
        });
    },
    triggerNegative (msg_text) {
        this.$q.notify({
          type: 'negative',
          message: msg_text,
          classes:'app-font'
        })
    },
    use_maddress: function() {
      //alert(this.for_id_no);
      this.address_list_dialog_show = true;
      /*
      this.maddress_btn = "hidden";
      this.caddress_btn = "";
      */
      //this.use_address(this.session_mcode);

    },
    use_address: function(mcode) {
        let self = this;
        const params = new URLSearchParams();
        params.append('mcode', mcode);
        self.axios({
          headers: {
            'Authorization': self.jwt_token
          }, 
          method: "post",
          url: self.url_api + "MemberClient/GetMAddressInfo.JSON.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          self.cart_info_local.receiver_name = responseDoc.getElementsByTagName('receiver')[0].textContent;
          self.cart_info_local.address1 = responseDoc.getElementsByTagName('address1')[0].textContent;
          self.cart_info_local.address2 = responseDoc.getElementsByTagName('address2')[0].textContent;
          self.cart_info_local.zipcode = responseDoc.getElementsByTagName('zip')[0].textContent;
          self.cart_info_local.phone_no = responseDoc.getElementsByTagName('mobile')[0].textContent;
          self.cart_info_local.district_id = responseDoc.getElementsByTagName('district_id')[0].textContent;
          self.cart_info_local.province_id = responseDoc.getElementsByTagName('province_id')[0].textContent;
          self.setProvinceSelected(responseDoc.getElementsByTagName('province')[0].textContent);
          self.validate_form();
        })
        .catch(function (error) {
          console.log("Error :" + error);
        });
    },
    setBranchSelected(selected_value) {
      var found = this.branchList.find(function(element) { 
        return element.value == selected_value; 
      });
      this.cart_info_local.branch_selected = found;
    },
    updateAddressBill: function () {
        //alert("upate address2 bill");
        let self = this;
        const params = new URLSearchParams();
        params.append('number', self.cart_info_local.cart_no);
        params.append('district_id', self.cart_info_local.district_id);
        params.append('province_id', self.cart_info_local.province_id);
        self.axios({
          headers: {
            'Authorization': self.jwt_token
          },
          method: "post",
          url: self.url_api + "MemberClient/eStore.Billing.Temp.Update.Address.JSON.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          let update_status = responseDoc.getElementsByTagName('update_status')[0].textContent;
          if (update_status == "OK") {
            self.fetchBillInfo();
          }
        })
        .catch(function (error) {
          console.log("Error :" + error);
        });
    },
    createBill: function () {
        //alert('create bill');
        let self = this;
        const params = new URLSearchParams();
        params.append('date', self.session_date);
        params.append('id_card', self.cart_info_local.for_id_no);
        params.append('mcode', "");
        params.append('mtype', self.session_mtype);
        self.axios({
          headers: {
            'Authorization': self.jwt_token
          },
          method: "post",
          url: self.url_api + "MemberClient/eStore.Billing.Temp.Insert.JSON.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          let insert_status = responseDoc.getElementsByTagName('insert_status')[0].textContent;
          if (insert_status == "OK") {
            self.bill_no = responseDoc.getElementsByTagName('bill_no')[0].textContent;
            self.cart_info_local.cart_no = self.bill_no;
            self.$emit("update");
            self.fetchBillInfo();
          } 
          //alert("Create Cart No : " + self.bill_no);
        })
        .catch(function (error) {
          console.log("Error :" + error);
        });
    },
    setProvinceSelected(selected_value) {
      var found = this.provinceList.find(function(element) { 
        return element.label == selected_value; 
      });
      this.cart_info_local.province_selected = found;
    },    
    fetchProvince: function () {
      let self = this;
      self.axios({
        method: "post",
         headers: {
            'Authorization': self.jwt_token
        },
        url: self.url_api + "MemberClient/Province.ListAll.JSON.aspx"
      })
      .then(function (response) {
        //console.log("province result :" + response.data[0].label);
        let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
        self.provinceList = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
        if (self.cart_info_local.province_selected == "") {
          self.cart_info_local.province_selected = self.provinceList[0];
        } 
      })
      .catch(function (error) {
        console.log("Error :" + error);
      });
    },
    fetchBillInfo: function () {
        let self = this;
        //alert("fetchBillInfo : " + self.cart_info_local.cart_no );
        //alert("for_id_no : " + self.cart_info_local.for_id_no);
        const params = new URLSearchParams();
        //console.log("fetchBillInfo cart_no : " +self.cart_info.cart_no);
        params.append('number', self.cart_info_local.cart_no);
        self.axios({
          headers: {
            'Authorization': self.jwt_token
          },
          method: "post",
          url: self.url_api + "MemberClient/eStore.Billing.Temp.View.JSON.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          //console.log("count_item : " + responseDoc.getElementsByTagName('count_item')[0].textContent);
          if ( parseInt(responseDoc.getElementsByTagName('count_item')[0].textContent ) > 0) {
            self.datalist = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
          } else {
            self.datalist = [];
          }
          self.total_items = self.datalist.length;
          self.total_amount = parseFloat(self.replaceAll(responseDoc.getElementsByTagName('total_ba')[0].textContent,",",""));
          self.total_pv = parseFloat(self.replaceAll(responseDoc.getElementsByTagName('total_pv')[0].textContent,",",""));
         
          self.cart_info_local.delivery_by = "1";
          //alert(responseDoc.getElementsByTagName('delivery_cost')[0].textContent);
          self.delivery_cost = parseFloat(self.replaceAll(responseDoc.getElementsByTagName('delivery_cost')[0].textContent,",",""));
          self.delivery_cost_current = self.delivery_cost;
          /*
          alert("self.datalist.length : " + self.datalist.length);
          alert("self.cart_info_local.province_id : " + self.cart_info_local.province_id);
          */
          /*
          if (self.datalist.length == 0 || self.cart_info_local.province_id == 0) {
            self.delivery_cost = 0;
          }
          
          if (self.datalist.length == 0) {
            self.delivery_cost = 0;
          }
          */
          //alert("delivery cost : " + self.delivery_cost);
          //alert("cart_info.delivery_by:" + self.cart_info.delivery_by);
          self.validate_form();
          //self.init = true;
        })
        .catch(function (error) {
          console.log("Error :" + error);
        });
      },
    gotoProductTab: function () {
      //let self = this;
      //alert("product list tab =>" + self.$parent.tab_selected);
      this.$emit("changeTab","product");
      //this.$emit("changeTab","gallery");
    },
    filterFnAutoselect (val, update) {
        // call abort() at any time if you can't retrieve data somehow
        setTimeout(() => {
          update(
            () => {
              if (val === '') {
                this.provinceOptions = this.provinceList
              }
              else {
                const needle = val.toLowerCase()
                this.provinceOptions = this.provinceList.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
                //console.log("filter result:" + this.provinceOptions[0].label);
                /*
                const result = members.filter((jsonOptions) => {
                  return jsonOptions.label > 25
                })
                this.options = result*/

              }
            },

            // next function is available in Quasar v1.7.4+;
            // "ref" is the Vue reference to the QSelect
            
            ref => {
              if (val !== '' && ref.options.length > 0 && ref.optionIndex === -1) {
                ref.moveOptionSelection(1, true) // focus the first selectable option and do not update the input-value
                ref.toggleOption(ref.options[ref.optionIndex], true) // toggle the focused option
              }
            }
          )
        }, 300)
      },

    abortFilterFn () {
        // console.log('delayed filter aborted')
    },
    changeOrdersType () {
        if (this.cart_info_local.ordersType == "D") {
            this.cart_info_local.delivery_by = "1";
        }
        if (this.ordersType != this.cart_info_local.ordersType) { // 1st created pass
          this.removeItemAll();
          this.delivery_cost = 0;
          this.ordersType = this.cart_info_local.ordersType;
        }
    }
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN'),
      session_mtype: get('SESSION_MTYPE'),
      session_id_no: get('SESSION_ID_NO'),
      session_mname: get('SESSION_MCODE_NAME'),
      session_date: get('SESSION_DATE'),
      compute_valid_for_id_no: function() {
        if (this.cart_info_local.for_mname.length > 0) {
          return true;
        } else {
          return false;
        }
      },
      total_amount_net: function() {
        return this.total_amount + this.delivery_cost;
      },
      type_description: function() {
        let type_desc;
        if (this.cart_info_local.type == "1") {
          type_desc = "ซื้อปกติ";
        } else {
          type_desc = "ซื้อรักษายอด";
        }
        return (type_desc);
      },
      /*
      for_id_no_isValid () {
        return this.for_id_no.length == this.mcode_length;
      },
      */
      receiver_name_isValid() {
        return this.cart_info_local.receiver_name.length >= 5;
      },
      address1_isValid () {
        return this.cart_info_local.address1.length >= 5;
      },
      zipcode_isValid () {
        return this.cart_info_local.zipcode.length == 5;
      },
      mobile_no_isValid () {
        return this.cart_info_local.mobile_no.length == 10;
      }
  },
  mounted () {
      //alert("cart_no : " + this.cart_info_local.cart_no);
      //this.fetchProvince();
  },
  created () {
      //alert("created cart");
      //console.log('Component created.');
      store.set("APP_TOOLBAR_TITLE","สั่งซื้อสินค้า");
      this.setUISize();
      this.fetchProvince();
      //alert("cart_info : " + this.cart_info.cart_no);
      if (this.cart_info.cart_no == "") {
        this.createBill();
        this.cart_info_local.for_id_no = this.session_id_no;
        this.cart_info_local.for_mname = this.session_mname;
      } else {
        this.cart_info_local = this.cart_info;
        this.fetchBillInfo();
      }
  },
  watch: {
    "$q.screen.width"() {
      this.setUISize();
    },
    /*
    "cart_info_local.ordersType" : function() {
        if (this.cart_info_local.ordersType == "D") {
            this.cart_info_local.delivery_by = "1";
        }
        this.delivery_cost = 0;
        if (this.ordersType != "") { // 1st created pass
          this.removeItemAll();
        } else {
          this.ordersType = this.cart_info_local.ordersType;
        }
    }, 
    */
    "cart_info_local.delivery_by" : function() {
        //alert(this.cart_info_local.delivery_by);
        if (this.cart_info_local.delivery_by == "2") {
            //alert(this.delivery_cost);
            this.delivery_cost = 0;
            this.submit_btn_disable = false;
        } else {
            this.delivery_cost = this.delivery_cost_current;
            this.validate_form();
        }
    },
    "cart_info.address2": function() {
        this.updateAddressBill();
        //alert("district_id : " + this.district_id);
        //alert("province_id : " + this.province_id);
        //this.calculate_delivery_cost();
        //console.log("==========> watch cart_info.delivery_by <===========");
    },
    cart_info_local: {
        handler: function () {
          //console.log("==========> watch cart_info <===========");
          /*
          console.log("province : "+ this.cart_info.province_selected.label);
          console.log("branch selected" + this.cart_info.branch_selected.label);
          */
          //this.calculate_delivery_cost();
          this.$emit("updateCartInfo",this.cart_info_local);
        },
        deep: true
    },

  }

}

