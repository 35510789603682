//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import store from '../store/index'
import { get } from 'vuex-pathify'
import EStoreAddToCartDialog from '@/components/EStoreAddToCartDialog.vue'

export default {
  components: {
    EStoreAddToCartDialog
  },
  name: "EstoreProductGallery",
  props: ["cart_no"],
  data () {
    return {
      ui_size: "",
      item_info: { 
                  cart_no: "", 
                  prodcode: "",  
                  prodname: "",
                  price: "0",
                  minimum: "0",
                  maximum: "0",
                  jsonPrice: [],
                   /*
                   cart_no: this.cart_no, 
                   prodcode: this.selected[0].prodcode,  
                   prodname: this.selected[0].prodname,
                   pv: this.selected[0].pv,
                   price: this.selected[0].price
                   */
                 },
      estore_add_to_cart_dialog_show: false,
      product_img_url: "",
      prodimage_exists: 0,
      pagination: {
        rowsPerPage: 0
      },
      loading: false,
      query:"1",
      datalist: [],
      selected: [],
      columns: [
        { name: 'prodcode', align: 'left', label: 'รหัสสินค้า', field: 'prodcode'},
        { name: 'prodname', align: 'left', label: 'ชื่อสินค้า', field: 'prodname'},
        { name: 'price', align: 'right', label: 'ราคา', field: 'price'}
      ],
      columns_mobile: [
        { name: 'prodname', align: 'left', label: 'รายละเอียดสินค้า', field: 'prodname'},
      ]
    }
  },
  methods: {
      product_img_url_fn: function (prodcode, prodimage_exists) {
        //alert(prodcode);
        let product_img_url;
        if (prodimage_exists) {
            product_img_url = this.url_api.replace('api','product') + prodcode + ".jpg";
        } else {
            product_img_url = this.url_api.replace('api','product') + "blank.png";
        }
        return product_img_url;
      },
      add_one: function(rownum) {
        let i = rownum - 1;
        this.datalist[i].quantity = parseInt(this.datalist[i].quantity) + 1;
        this.datalist[i].total_amount = this.formatnumber(parseInt(this.datalist[i].quantity) * parseFloat(this.replaceAll(this.datalist[i].price,",","")),2);
        this.datalist[i].total_pv = this.formatnumber(parseInt(this.datalist[i].quantity) * parseFloat(this.replaceAll(this.datalist[i].pv,",","")),2);
      },
      remove_one: function(rownum) {
        let i = rownum - 1;
        if (this.datalist[i].quantity > 1) {
          this.datalist[i].quantity = parseInt(this.datalist[i].quantity) - 1;
          this.datalist[i].total_amount = this.formatnumber(this.datalist[i].quantity * parseFloat(this.replaceAll(this.datalist[i].price,",","")),2);
          this.datalist[i].total_pv = this.formatnumber(parseInt(this.datalist[i].quantity) * parseFloat(this.replaceAll(this.datalist[i].pv,",","")),2);
        }
      },
      alert_url: function(prodcode) {
        alert(this.url_api.replace('api','product') + prodcode.substring(0, prodcode.search("-")) + ".jpg");
      },

      postData: function (prodcode, quantity) {
        var self = this;
        //alert("session_date : " + self.session_date);
        self.loading = true;
        const params = new URLSearchParams();
        params.append("mtype", self.session_mtype);
        params.append('number', self.cart_no);
        params.append('bill_date', self.session_date);
        params.append('prodcode', prodcode);
        params.append('quantity', quantity);
        self.axios({
          headers: {
            'Authorization': self.jwt_token
          },
          method: 'post',
          timeout: 0,
          url: self.url_api + 'MemberClient/eStore.Billing.Temp.InsertItem.JSON.aspx',
          data: params
        })
        .then(function (response) {
            //console.log(response);
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let result = responseDoc.getElementsByTagName('update_status')[0].textContent;
            let result_txt = responseDoc.getElementsByTagName('message')[0].textContent;
            if (result == "OK") {
              self.loading = false;
              self.$q.loading.hide();
              self.gotoCartTab();
              //self.triggerPositive(result_txt);
            } else {
              self.loading = false;
              self.$q.loading.hide();
              self.triggerNegative(result_txt);
            }
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.triggerNegative("ไม่สามารถบันทึกข้อมูลได้");
            self.loading = false;
            self.$q.loading.hide();
            self.submit_btn_disable = false;
        });
       
      },
      gotoCartTab () {
        this.$emit("changeTab","cart");
      },
      triggerPositive (msg_text) {
        this.$q.notify({
          type: 'positive',
          progress: true,
          message: msg_text,
          classes:'app-font'
        });
      },
      triggerNegative (msg_text) {
        this.$q.notify({
          type: 'negative',
          message: msg_text,
          classes:'app-font'
        })
      },
      refresh (done) {
        setTimeout(() => {
          this.getData();
          done();
        }, 1000)
      },
      setUISize() {
          //if (this.$q.screen.width > 1023) { 
          if (this.$q.screen.width > 800) {
          //console.log('screen large');
          this.ui_size = "D";
          }
          else {
          //console.log('screen small');
          this.ui_size = "M";
          }
      },
      /*
      open_add_to_cart_dialog: function(item) {
        this.item_info.cart_no = this.cart_no;
        this.item_info.prodcode = item.prodcode;
        this.item_info.prodname = item.prodname;
        this.item_info.price = item.price;
        this.item_info.minimum = item.minimum;
        this.item_info.maximum = item.maximum;
        this.item_info.jsonPrice = item.jsonPrice;
        this.estore_add_to_cart_dialog_show = true;
      },
      */
      estore_add_to_cart_dialog_hide () {
        this.estore_add_to_cart_dialog_show = false;
        //console.log("hide set estore_add_to_cart_dialog_show = " + this.estore_add_to_cart_dialog_show);
      },
      requestRowClick: function(selected_prodcode, selected_row) {
          //console.log("row click :" + selected_mcode);
          if (this.selected.prodcode !== selected_prodcode) {
            this.selected.splice(0,1); //delete item from selected array 
            this.selected.push(selected_row); //add item from selected array 
            //this.$emit("onSeleted", selected_row);
            let prodcode = selected_prodcode;
            this.product_img_url = this.url_api.replace("api","product") + prodcode.substring(0, prodcode.search("-")) + ".jpg";
            //console.log("product_img_url :" + this.url_api.replace("aspx","Product") + selected_prodcode+".jpg");
          }
      },
      getData: function () {
        var self = this;
        self.loading = true;
        const params = new URLSearchParams();
        self.rowsPerFetch = 200;//self.appRowsPerFetch;
        self.axios({
          method: 'post',
          headers: {
            'Authorization': self.jwt_token
          },
          timeout: 0, // Let's say you want to wait at least Unlimits mins,
          url: self.url_api + 'MemberClient/eStore.Product.List.JSON.aspx',
          data: params
        })
        .then(function (response) {
            //console.log(response);
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let record_count = parseInt(responseDoc.getElementsByTagName('recordcount')[0].textContent);
            if (record_count > 0) {
              self.datalist = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
              self.selected = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent).slice(0, 1);
              //console.log(self.datalist[0].jsonPrice);
              //alert(self.datalist[0].jsonPrice[10].from_qty);
              let prodcode = self.selected[0].prodcode;
              let product_img_filename;
              if (prodcode.indexOf("-") >= 0) {
                product_img_filename = prodcode.substring(0, prodcode.search("-"));
              } else {
                product_img_filename = prodcode;
              }
              self.product_img_url = self.url_api.replace("api","product") + product_img_filename + ".jpg";
            } else {
              self.datalist = [];
              self.selected = [];
            }
           
            self.loading = false;
            self.$q.loading.hide();
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.datalist = [];
            self.selected = [];
            self.loading = false;
            self.$q.loading.hide();
        });
      },
  },
  mounted () {
      //let self = this;
      //console.log('EStoreOrdersDetail Dialog mounted.');
      this.getData();
  },
  watch: {
    "$q.screen.width"() {
      this.setUISize()
    },
    /*
    show_status: function () {
      //console.log("fixed :" + this.fixed);
      this.fixed = this.show_status;
    }*/
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN'),
      session_mtype: get('SESSION_MTYPE'),
      session_date: get('SESSION_DATE')
  },
  created () {
    this.setUISize();
    store.set("APP_TOOLBAR_TITLE","สั่งซื้อสินค้า");
    //console.log('Component created.');
  }
}
