//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify'

export default {
  name: "ProductRateDialog",
  props: ["show_status","product"],

  data () {
    return {
      full_width_boolean: false,
      dialog_style: "",
      submit_btn_disable: false,
      isPwd: true,
      fixed: false,
      loading: false,
      quantity: 0,
      total_pv: 0,
      total_mv: 0,
      total_amount: 0,
      item_info_local: null,
      datalist: [],
      pagination: {
        rowsPerPage: 0
      },
      selected: [],
      columns: [
        { name: 'level', align: 'right', label: 'เรทที่', field: 'level' /*, sortable: true */ },
        { name: 'from_qty', align: 'right', label: 'จำนวน (แพ็ค)', field: row => row.from_qty, format: val => `${this.formatnumber(val,0)}`/*, sortable: true */ },
        //{ name: 'to_qty', align: 'right', label: 'ถึงจำนวน(แพ็ค)', field: row => row.to_qty, format: val => `${this.formatnumber(val,0)}`},
        { name: 'price', align: 'right', label: 'ราคา/แพ็ค', field: row => row.price, format: val => `${this.formatnumber(val,2)}` /*, sortable: true */ },
        /*
        { name: 'delivery_cost', label: 'ค่าจัดส่ง', field: row => row.delivery_cost, format: val => `${this.formatnumber(val,0)}`},
        { name: 'total_amount', label: 'รวมสุทธิ', field: row => row.total_amount, format: val => `${this.formatnumber(val,0)}`},
        */
      ],
    }
  },
  methods: {
      requestRowClick: function(selected_mcode, selected_row) {
          this.selected.splice(0,1);
          this.selected.push(selected_row);
      },
      setDialogSize() {
        //if (this.$q.screen.width > 1023) { 
        if (this.$q.screen.width > 800) {
          //console.log('screen large');
          this.full_width_boolean = false;
          this.dialog_style = "background-color:#f5fafe; width: 520px; max-width: 42vw;";
        }
        else {
          //console.log('screen small');
          this.full_width_boolean = true;
          this.dialog_style = "height:470px; zoom: 0.75;";
        }
      },
      formatnumber(num, decimal) {
        return num.toFixed(decimal).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
      },
      triggerPositive (msg_text) {
        this.$q.notify({
          type: 'positive',
          progress: true,
          message: msg_text,
          classes:'app-font'
        });
      },
      triggerNegative (msg_text) {
        this.$q.notify({
          type: 'negative',
          message: msg_text,
          classes:'app-font'
        })
      },
      clearForm: function() {
        this.quantity = 0;
      },
      onHide() {
        //console.log("onHide");
        this.quantity = 0;
        this.$emit("onHide", "");
      },
      onShow() {
      },
      setLevel: function () {
          //alert("total_qty : " + this.product.total_qty);
          if (this.datalist.length > 0) {
              for (let i = 0; i < this.datalist.length; i++) {
                if (parseInt(this.product.total_qty) >= parseInt(this.datalist[i].from_qty) && parseInt(this.product.total_qty) <= parseInt(this.datalist[i].to_qty)) {
                    this.selected.splice(0,1);
                    this.selected.push(this.datalist[i]);
                    break;
                }
              }
          }
      },
      getData: function () {
        //alert("getData product list");
        var self = this;
        self.loading = true;
        const params = new URLSearchParams();
        params.append('prodcode', this.product.prodcode);

        self.axios({
          method: 'post',
          headers: {
            'Authorization': self.jwt_token
          },
          timeout: 0, // Let's say you want to wait at least Unlimits mins,
          url: self.url_api + 'MemberClient/Product.Rate.List.JSON.aspx',
          data: params
        })
        .then(function (response) {
            //console.log(response);
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let record_count = parseInt(responseDoc.getElementsByTagName('recordcount')[0].textContent);
            if (record_count > 0) {
              self.datalist = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
              self.selected = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent).slice(0, 1);
              //console.log(self.datalist[0].jsonPrice);
              //alert(self.datalist[0].jsonPrice[10].from_qty);
              //let prodcode = self.selected[0].prodcode;
            } else {
              self.datalist = [];
              self.selected = [];
            }
            self.setLevel();
            self.loading = false;
            self.$q.loading.hide();
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.datalist = [];
            self.selected = [];
            self.loading = false;
            self.$q.loading.hide();
        });
      }
  },
  mounted () {
      //console.log('EStoreAddToCard Dialog mounted.');
  },
  watch: {
    show_status: function () {
      //console.log("fixed :" + this.fixed);
      this.fixed = this.show_status;
      if (this.fixed) {
        this.getData();
      }
    },
    "$q.screen.width"() {
      this.setDialogSize();
    },
  },
  computed: {
      bank_info: function () {
        return this.bank_code + " - "  + this.bank_name;
      },
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN'),
      session_date: get('SESSION_DATE'),
      session_mtype: get('SESSION_MTYPE')
  },
  created () {
      //console.log('Component created.');
      this.setDialogSize()
      //this.mcode_current = this.mcode_start;
      //this.fetchPosition();
  }    

}
