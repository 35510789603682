//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EStoreProductGallery from '@/components/EStoreProductGallery.vue';
import EStoreProductList from '@/components/EStoreProductList.vue';
import EStoreOrdersHistory from '@/views/EStoreOrdersHistory.vue';
import EStoreCart from '@/components/EStoreCart.vue';
import store from '../store/index';
import { get } from 'vuex-pathify';

export default {
  name: 'EStore',
  components: {
    EStoreProductGallery,
    EStoreProductList,
    EStoreOrdersHistory,
    EStoreCart
  },
  data () {
    return {
      tab_selected: "cart",
      cart_info : {
                    cart_no: "",
                    type: "1",
                    ordersType: "",
                    for_id_no: "",
                    for_mname: "",
                    delivery_by: "1",
                    branch_selected: "", 
                    receiver_name: "",
                    address1: "",
                    address2: "",
                    province_selected: "", 
                    zipcode: "",
                    phone_no: "",
                    mobile_no: "",
                    district_id: "",
                    province_id: "",
                  }
    }
  },
  methods: {
    setCartInfo: function(cart_info) {
      this.cart_info = cart_info;
      //alert("change cart_info");
    },
    changeTab: function (tabName) { 
      this.tab_selected = tabName;
    },
    alertTabName: function () {
      //alert("alertTabName : " +this.tab_selected);
    }
  },
  mount() {
    //console.log("mount current tab : " + this.tab_selected);
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN'),
      session_mtype: get('SESSION_MTYPE'),
      session_id_no: get('SESSION_ID_NO'),
      session_mname: get('SESSION_MCODE_NAME'),
      session_date: get('SESSION_DATE'),
  },
  created () {
    store.set("APP_TOOLBAR_TITLE","สั่งซื้อสินค้า");
  }
}

