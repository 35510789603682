//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify'
import EpointDialog from '@/components/EpointDialog.vue'
import QrCode30Dialog from '@/components/QrCode30Dialog.vue'

export default {
  name: "PaymentDialog",
  props: ["show_status","number","total_amount_net"],
  components: {
    EpointDialog,
    QrCode30Dialog
  },
  data () {
    return {
      total_amount_net_str : "",
      full_width_boolean: false,
      slip_dialog_visible: false,
      cancel_epoint_dialog_visible: false,
      qr_confirm_dialog_visible: false,
      epoint_dialog_show: false,
      qr_code30_dialog_show: false,
      dialog_style: "",
      total_ev: 0,
      total_qr: 0,
      epoint: 0,
      fixed: false,
      loading: false,
      trans_no: "",
      trans_date: "",
      ref1: "",
      amount: "",
      qrImage: "",
    }
  },
  methods: {
      ePointCancel() {
          this.cancel_epoint_dialog_visible = false; 
          let self = this;
          self.$q.loading.show();
          const params = new URLSearchParams();
          params.append('number', this.number);
          params.append('epoint', this.total_ev);
          self.axios({
            method: 'post',
            headers: {
              'Authorization': self.jwt_token
            },        
            timeout: 0,
            url: self.url_api + 'MemberClient/ePoint.Cancel.JSON.aspx',
            data: params
          })
          .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let update_status = parseInt(responseDoc.getElementsByTagName('update_status')[0].textContent);
            let update_message = responseDoc.getElementsByTagName('update_message')[0].textContent;
            if (update_status == 1) {
              self.triggerPositive(update_message);
              self.getPaymentInfo();
            } else {
              self.triggerNegative(update_message);
            }
            self.$q.loading.hide();
          })
          .catch(function (error) {
              console.log("Error :" + error);
              self.triggerNegative("Error");
              self.$q.loading.hide();
          });
      },
      updateEpoint(epoint) {
         this.epoint_dialog_show = false;
         console.log("use e-Point : " + epoint);
         //alert("use e-Point : " + epoint);
         this.getPaymentInfo();
      },
      finishEpoint(epoint) {
         //alert("use e-Point : " + epoint);
         console.log("use e-Point : " + epoint);
         this.epoint_dialog_show = false;
         this.onHide();
      },
      confirmQR() {
          if (this.total_ev == 0 && this.epoint > 0) {
            this.qr_confirm_dialog_visible = true;
          } else {
            this.qr_code30_dialog_open();
          }
      },
      epoint_dialog_open() {
          this.epoint_dialog_show = true;
      },
      epoint_dialog_hide() {
          this.epoint_dialog_show = false;
      },
      qr_code30_dialog_open() {
          this.qr_confirm_dialog_visible = false;
          this.qr_code30_dialog_show = true;
      },
      qr_code30_dialog_hide() {
          this.qr_code30_dialog_show = false;
          this.onHide();
      },
      triggerPositive (msg_text) {
          this.$q.notify({
            type: 'positive',
            progress: true,
            message: msg_text,
            classes:'app-font'
          });
      },
      triggerNegative (msg_text) {
        this.$q.notify({
          type: 'negative',
          message: msg_text,
          classes:'app-font'
        })
      },
      onHide() {
        //console.log("onHide");
        this.$emit("onHide", "");
        this.total_ev = 0;
        this.total_qr = 0;
        //this.$parent.scode_dialog_show = false;
      },
      onShow() {
        this.getPaymentInfo();
        /*
        this.$q.loading.show();
        this.getData();
        */
      },
      getPaymentInfo: function () {
        let self = this;
        self.$q.loading.show();
        const params = new URLSearchParams();
        params.append('number', self.number);
        self.axios({
          method: 'post',
          headers: {
            'Authorization': self.jwt_token
          },  
          timeout: 0,
          url: self.url_api + 'MemberClient/ePoint.GetPaymentInfo.JSON.aspx',
          data: params
        })
        .then(function (response) {
                let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
                self.total_ev = responseDoc.getElementsByTagName('total_ev')[0].textContent;
                self.total_qr = responseDoc.getElementsByTagName('total_qr')[0].textContent;
                self.epoint = responseDoc.getElementsByTagName('epoint')[0].textContent;
                self.total_amount_net_str = self.total_amount_net;
                //alert("total_qr : " + self.total_qr);
                self.$q.loading.hide();
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.loading = false;
            self.$q.loading.hide();
        });
      },
      validate_transaction: function () {
        let self = this;
        self.$q.loading.show();
        const params = new URLSearchParams();
        params.append('trans_no', self.trans_no);
        self.axios({
          method: 'post',
          headers: {
            'Authorization': self.jwt_token
          },  
          timeout: 0,
          url: self.url_api + 'MemberClient/eStore.Payment.QR30.TQRC.JSON.aspx',
          data: params
        })
        .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let paid_status = responseDoc.getElementsByTagName('status_code')[0].textContent;
            console.log("paid_status : " + paid_status);
            if (paid_status == "1000") {
                self.triggerPositive("ได้รับชำระเงินเรียบร้อยแล้ว เตรียมการจัดส่งสินค้า");
                self.$q.loading.hide();
                self.fixed = false;
            } else {
                self.triggerNegative("ไม่พบรายการชำระเงิน!");
                self.$q.loading.hide();
                self.fixed = false;
            }
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.loading = false;
            self.$q.loading.hide();
        });
      },
      getData: function () {
        var self = this;
        self.$q.loading.show();
        self.loading = true;
        const params = new URLSearchParams();
        params.append('number', self.number);
        self.axios({
          method: 'post',
          headers: {
            'Authorization': self.jwt_token
          },  
          timeout: 0,
          url: self.url_api + 'MemberClient/eStore.Payment.QR30.JSON.aspx',
          data: params
        })
        .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            self.trans_no = responseDoc.getElementsByTagName('trans_no')[0].textContent;
            self.trans_date = responseDoc.getElementsByTagName('trans_date')[0].textContent;
            self.ref1 = responseDoc.getElementsByTagName('ref1')[0].textContent;
            self.amount = responseDoc.getElementsByTagName('amount')[0].textContent;
            self.qrImage = "data:image/png;base64," + responseDoc.getElementsByTagName('qrImage')[0].textContent;
            //alert("trans_no : " + self.trans_no);
            console.log("trans_no : " +self.trans_no);
            //console.log("qrImage : " + self.qrImage);
            self.loading = false;
            self.$q.loading.hide();
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.loading = false;
            self.$q.loading.hide();
        });
      },
      setDialogSize() {
        //if (this.$q.screen.width > 1023) { 
        if (this.$q.screen.width > 800) {
          //console.log('screen large');
          this.full_width_boolean = false;
          this.dialog_style = "background-color:#f5fafe; width: 320px; max-width: 40vw;";
        }
        else {
          //console.log('screen small');
          this.full_width_boolean = true;
          this.dialog_style = "height:auto;"
          //this.dialog_style = "height:465px;";
        }
      },
      onRejected (rejectedEntries) {
        console.log(rejectedEntries);
        this.$q.notify({
          type: 'negative',
          /*message: `${rejectedEntries.length} file(s) did not pass validation constraints`*/
          message: `File size larger than 12 MB.`
        })
      },
      refreshImg() {
        //this.$refs.imgElement.src = this.bypassCache(this.url_img + this.id + ".jpg");
      },
    /*
    ,
    // when props.ok() gets called
    onOk (data) { },

    // when props.cancel() gets called
    onCancel () { },

    // when we show it to the user
    onShow () { },

    // when it gets hidden
    onHide () { },

    // custom handler
    async choose (okFn, hero) {
      if (this.name.length === 0) {
        this.$q.dialog({
          title: 'Please specify your name!',
          message: `Can't buy tickets without knowing your name.`
        })
      }
      else {
        await okFn()
        this.$q.notify(`Ok ${this.name}, going with ${hero}`)
      }
    }
    */
  },
  mounted () {
      //let self = this;
      //console.log('EStoreOrdersDetail Dialog mounted.');
      /*
      this.$q.loading.show();
      this.mcode_current = this.mcode_start;
      this.getData();
      
      if (this.mcode_start != "") {
        this.mcode_current = this.mcode_start;
        this.$q.loading.show();
        this.getData();
      }*/
      /*
      window.addEventListener("keyup", e => {
        switch (e.keyCode) {
          case 38:
            //alert("up" + e.keyCode + "current row:" + this.current_row.toString());
            //alert(parseInt(this.current_row)-1);
            if (parseInt(this.current_row) > parseInt(this.min_row)) {
              alert(parseInt(this.current_row)-1);
              this.current_row = parseInt(this.current_row) - 1
              this.selected.splice(0,1);
              this.selected.push(this.datalist[parseInt(this.current_row)-1]);
            }
            break;
          case 40:
            //alert("down" + e.keyCode + "current row:" + this.current_row.toString());
            //alert(parseInt(this.current_row)+1);
            if (parseInt(this.current_row) < parseInt(this.max_row)) {
              alert(parseInt(this.current_row)+1);
              this.current_row = parseInt(this.current_row) + 1;
              this.selected.splice(0,1);
              this.selected.push(this.datalist[parseInt(this.current_row)-1]);
            }
            break;
        }
      });
      */
      //this.writeConsole();

      /*
      const self = this;
      console.log('component API URL:' + self.url_api + 'Member/Member.View.JSON.aspx');
ss
      */
  },
  watch: {
    "$q.screen.width"() {
      this.setDialogSize();
    },
    show_status : function () {
      //console.log("fixed :" + this.fixed);
      this.fixed = this.show_status;
    },
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN'),
      total_ev_str() {
        return this.formatnumber(parseFloat(this.total_ev),2);
      },
      total_qr_str() {
        return this.formatnumber(parseFloat(this.total_qr),2);
      },
      getHeader() {
        let headerArr = [
          { name: 'Authorization', value: this.jwt_token },
          { name: 'X-Custom-Data', value: this.number }
        ];
        return headerArr
      },
      getUrl (files) {
        return this.url_api + "MemberClient/UploaderImage.aspx?id=" + this.number + "&count=" + files.length;
      },
  },
  created () {
      this.setDialogSize();
      //console.log('Component created.');
  }
}
